import React, {
    Fragment, 
} from 'react';
import propTypes from 'prop-types';
import {
    useTranslation, 
} from 'react-i18next';
import {
    useSelector, 
} from 'react-redux';
import styled from 'styled-components';

import {
    Typography,
    Divider, 
} from '@testamento/design-system';


import SignIn from '../signin';
import SignUp from '../signup';
import OAuthButton from '../oauth-button';
import {
    AuthState, 
} from '../../../constants';


const SpacedHeading = styled(Typography.HeadingS)`
    margin-bottom : 3.6rem;
`;

const WrapperAuthProviders = ({ authDisplay, text }) => {
    const { isOAuth, isCognito , isDivider } = authDisplay;
    const { heading, description, or, authState } = text;
    const { t } = useTranslation();

    return <Fragment>
        <SpacedHeading>{t(heading)}</SpacedHeading>
        {isOAuth && !isCognito
            ? <Typography.Body>{t(description)}</Typography.Body>
            : null
        }
        {isOAuth
            ? <OAuthButton customState={authState}/>
            : null
        }
        {isDivider
            ? <Divider margin="2rem 0">{t(or)}</Divider>
            : null
        }
    </Fragment>;
};
WrapperAuthProviders.propTypes = {
    authDisplay : propTypes.shape({
        isOAuth : propTypes.bool,
        isCognito : propTypes.bool,
        isDivider : propTypes.bool,
    }),
    text : propTypes.shape({
        heading : propTypes.string,
        description : propTypes.string,
        or : propTypes.string,
        authState : propTypes.string,
    }),
};

const CurrentSignForm = () => {

    const authState = useSelector(state => state.authState);
    const config = useSelector(state => state.config);

    const { global = {} } = config;
    const { authProviders, colorBackground } = global;

    const isCognito = authProviders ? authProviders.includes('Cognito') : false;
    const SignFormComponent = [
        { authStateIn: AuthState.SignIn, componentIs: SignIn },
        { authStateIn: AuthState.SignUp, componentIs: SignUp }
    ].reduce(
        (Component, check) => (authState === check.authStateIn
            ? check.componentIs
            : Component
        ),
        null
    );

    return (isCognito
        ? <SignFormComponent {...{ colorBackground }} />
        : null
    );
};

/**
 *
 * @returns ReactElement
 */
export default function CurrentSignFormWithWrapper() {
    const authState = useSelector(state => state.authState);
    const config = useSelector(state => state.config);

    const { global = {} } = config;
    const { authProviders } = global;

    const isCognito = authProviders ? authProviders.includes('Cognito') : false;
    const isOAuth = authProviders ? authProviders.find(provider => provider !== 'Cognito') !== undefined : false;
    const authDisplay = {
        isCognito : isCognito,
        isOAuth : isOAuth,
        isDivider : isCognito && isOAuth,
    };

    return <Fragment>
        <WrapperAuthProviders
            authDisplay={authDisplay}
            text={{
                heading : 'auth.' + authState + '.heading',
                description : 'auth.' + authState + '.description',
                or : 'auth.or',
                authState : authState,
            }}
            colorBackground
        />
        <CurrentSignForm />
    </Fragment>;
}
