//@ts-check

import React, {
    Suspense,
    useEffect,
    useState,
} from 'react';
import {
    useTranslation,
    I18nextProvider,
} from 'react-i18next';
import {
    Route,
    Routes,
    BrowserRouter as Router,
} from 'react-router-dom';
import propTypes from 'prop-types';
import {
    useSelector,
} from 'react-redux';
import {
    createInstance,
    MatomoProvider,
    useMatomo,
} from '@jonkoops/matomo-tracker-react';
import {
    logger,
} from '@testamento/design-system/dist/lib';
import '@testamento/design-system/dist/index.css';
import {
    Cognito,
} from '@testamento/react-components';
import {
    Theme,
    Overlay,
} from '@testamento/design-system';
import store from './store';
import {
    SecureRedirection,
    LogoutRedirection,
    ResetPassword,
} from './contexts';
import {
    NetworkStatusProvider,
    useNetworkStatus,
} from './contexts/use-network-status';
import CognitoOAuth from './contexts/CognitoOAuth.js';
import {
    getPartnerFromDomain,
} from './utils/utils';
import {
    getValidToken,
    setPartner,
    setConfig,
} from './store/actions';
import SessionTokenError from './pages/SessionTokenError';
import DeleteAccount from './pages/DeleteAccount';
import i18n, {
    configI18n,
} from './utils/i18n';
import NetworkError from './pages/NetworkError';
import ConnectedPage from './components/ConnectedPage';
import {
    SIGNUP_UNAVAIBLE_WITHOUT_TOKEN,
    AUTHENTICATED_PATHES,
} from './constants';
import parameters from './parameters.json';
import User from './contexts/User.jsx';
const {
    useCognitoHubDispatch,
} = Cognito;

const {
    analytics_urlBase,
    analytics_siteId,
} = parameters;
const {
    OverlayController,
} = Overlay;

const matomoInstance = createInstance({
    urlBase: analytics_urlBase,
    siteId: analytics_siteId,
    disabled: (
        process.env.NODE_ENV === 'test'
        || analytics_siteId === '99'
    ),
    enableLinkTracking: false,
    configurations: {
        'enableJSErrorTracking': true,
    },
});

const debug = logger('planner:app');

/**
 *
 * @param {*} props
 * @returns
 */
export function App (props) {
    const hubDispatch = useCognitoHubDispatch();
    const { t } = useTranslation();

    const isOnline = useNetworkStatus();
    const validatedToken = useSelector(state => state.validatedToken);
    const [consumeTokenError, setConsumeTokenError] = useState(false);

    const { data = {} } = props;
    const { global = {} } = data;

    const { nameInLabel, colorBackground, theme } = global;
    document.title = t('global.title', { partner: nameInLabel });

    const consumedToken = (new URLSearchParams(window.location.search)).get('token');

    useEffect(() => {
        if (validatedToken === false) setConsumeTokenError(true);
    }, [validatedToken]);

    useEffect(() => {
        if (consumedToken) {
            store.dispatch(
                getValidToken(
                    getPartnerFromDomain(window.location.host),
                    consumedToken,
                    hubDispatch)
            );
        }
    }, [consumedToken, hubDispatch]);


    debug('Current language ' + i18n.language);
    debug('%cplanner', 'color: cyan;background-color: #000000;font-weight: bold;', props);

    if (
        consumeTokenError
        || consumedToken === ''
    ) return (
        <SessionTokenError colorBackground={colorBackground} />
    );

    return (
        <div className="App">
            {isOnline
                ? <Suspense fallback="Loading ...">
                    <Theme.Provider
                        base={{
                            colors: {
                                primary: '#ED4A35',
                                white: '#FFFFFF',
                                borders: '#D8D8D8',
                            },
                        }}
                        override={theme || {}}
                    >
                        <Routes>
                            <Route
                                path={AUTHENTICATED_PATHES.DELETE_ACCOUNT}
                                element={<User {...props}
                                    PageWrapper={ConnectedPage}
                                    PageComponent={DeleteAccount}
                                />}
                            />
                            <Route
                                path={AUTHENTICATED_PATHES.CHANGE_PASSWORD}
                                element={<User {...props}
                                    PageWrapper={ConnectedPage}
                                    PageComponent={ResetPassword}
                                />}
                            />
                            <Route
                                path={AUTHENTICATED_PATHES.LOGOUT}
                                element={<LogoutRedirection />}
                                // />}
                            />
                            <Route path={'/'}
                                element={<User {...props}
                                    PageComponent={SecureRedirection}
                                />}
                            />
                        </Routes>
                    </Theme.Provider>
                </Suspense>
                : <NetworkError {...{ colorBackground }} />
            }
        </div>
    );
}

App.propTypes = {
    data: propTypes.shape({
        global: propTypes.shape({
            nameInLabel: propTypes.string,
            finalFunction: propTypes.shape({
                src: propTypes.string,
                name: propTypes.string,
            }),
        }),
    }),
};

/**
 *
 * @param {Object} props
 * @param {Object} props.config
 * @returns
 */
export default function AppWrapper({
    config,
}) {
    const { enableLinkTracking } = useMatomo();
    enableLinkTracking();
    const partnerName = getPartnerFromDomain(window.location.host);
    const { properties = {}, tokenValidation } = config;
    const { global = {}, appSettings = {} } = properties;
    const { planner } = appSettings;

    if (planner === undefined) {
        throw new Error(`planner not available for ${partnerName}`);
    }

    // this properties blocks translation paralellization
    const { nameInPath, colorBackground } = global;
    const i18nConfig = configI18n(nameInPath);
    debug('AppWrapper evaluate');

    useEffect(() => {
        store.dispatch(setPartner(partnerName));
        store.dispatch(setConfig(properties));
    }, [nameInPath, properties]);

    return (
        <MatomoProvider value={matomoInstance}>
            <I18nextProvider i18n={i18nConfig}>
                <NetworkStatusProvider>
                    <CognitoOAuth colorBackground={colorBackground}>
                        <OverlayController>
                            <Router>
                                {tokenValidation && tokenValidation !== SIGNUP_UNAVAIBLE_WITHOUT_TOKEN
                                    ? <SessionTokenError colorBackground={colorBackground} />
                                    : <App data={config.properties} />
                                }
                            </Router>
                            <Overlay />
                        </OverlayController>
                    </CognitoOAuth>
                </NetworkStatusProvider>
            </I18nextProvider>
        </MatomoProvider>
    );
}
AppWrapper.propTypes = {
    config: propTypes.object,
};
