import styled, {
    css,
} from 'styled-components';
import {
    hsl, 
} from 'd3-color';


export const ActionsWrapper = styled.div`
    margin-top: 23px;
`;

export const FixedPage = styled.main`
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: white;
    padding: 23px;
    min-height: calc(100vh - (23px * 2));
    width: 100%;
`;

export const PageLayout = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 50%;
    margin: 0 auto;
    padding: 95px 13px;
    @media(max-width: 768px) {
        padding: 24px 13px;
        width: 100%; 
    }
`;

export const HeadingSection = styled.header`
    display: flex;
    align-items: center;
    gap: 14px;
    margin-bottom: 36px;
`;

const iconModifiers = {
    background: css`${({ background }) => (background ? background : '')}`,
};

export const IconContainer = styled.span`
    padding: 14px;
    border-radius: 100%;
    background: ${iconModifiers.background};
`;

const rltLayout = css`
    display: flex;
    gap: 24px;
    justify-content: flex-end;
    width: 100%;
        @media(max-width: 1024px) {
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
`;
const ApplyBackgroundColor = (background) => {
    return css`
        background: ${background};
        padding: 23px;
        margin: 0;
    `;
};
const ApplyEmphasisStyles = (color, emphasis) => {
    return css`
        text-align: center;
        padding: 23px;
        text-align: center;
            p {
                :first-of-type {
                    margin-bottom: 8px;
                }
            }
        color: ${color ? color : ''};
        border: 2px solid ${emphasis};
        background: ${(() => {
        const backgroundColor = hsl(emphasis);
        backgroundColor.opacity = .1;
        return backgroundColor; })()};}
    `;
};

const SectionModifiers = {
    emphasis: css`${(props) => (
        props.emphasis ? ApplyEmphasisStyles(props.colors, props.emphasis) : ' margin: 36px 0;'
    )}`,
    background: css`${({ background }) => (
        background ? ApplyBackgroundColor(background) : null
    )}`,
    layoutDirection: css`${({ rtl }) => (
        rtl ? rltLayout : null)}`,
};

export const Section = styled.section`
    border-radius: 8px;
    width: 100%;
    ${SectionModifiers.emphasis}
    ${SectionModifiers.background}
    ${SectionModifiers.layoutDirection}
`;

export const Spacer = styled.hr`
    all: unset;
    display: block;
    padding: 8px 0;
`;

export const Container = styled.div`
    width: 100%;
`;
