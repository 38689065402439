//@ts-check

import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import {
    logger,
} from '@testamento/design-system/dist/lib';

const debug = logger('planner:init');
const Loader = styled.div`
    top: calc(50% - 50px);
    left: calc(50% - 50px);
    position: absolute;
    border-radius: 50%;
    border: 11px solid #f3f3f3;
    border-top: 11px solid #C5281C;
    width: 100px;
    height: 100px;
    -webkit-animation: spin 1s linear infinite; /* Safari */
    animation: spin 1s linear infinite;
`;
/**
 *
 * @param {{
 *      title: string;
 *      context: string
 * }} props
 * @returns
 */
export default function LoaderReact (props) {
    const { context, ...restProps } = props;
    React.useEffect(() => {
        debug(`Mount loader for context ${context}`);
        return ()=>{
            debug(`Unmount loader for context ${context}`);
        };
    });

    // here, we already need translation to satisfy screen reader
    return (
        <Loader  role="alert" aria-label="Loading" {...restProps} />
    );
}
LoaderReact.propTypes = {
    title: propTypes.string,
    context: propTypes.string,
};
