import React, {
    useState, 
} from 'react';
import {
    useTranslation, 
} from 'react-i18next';
import propTypes from 'prop-types';
import styled from 'styled-components';
import {
    Typography, Button, 
} from '@testamento/design-system';
import {
    PartnerButton, 
} from '../Block/Styling';

const NoticeContainer = styled.section`
    width: 100%;
    position: fixed;
    background: black;
    color: white;
    padding: 36px 14px;
    z-index: 9999;
    bottom: 0;
    left: 0;
`;

const NoticeFrame = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    text-align: center;

    p {
        margin-bottom: 13px;
    }
`;

export const NoticeContent = styled.div`
    width: 66.6%;

    @media(max-width: 1056px){
        width: 100%;
    }
`;

/**
 *
 * @param {Object} props
 * @param {Object} props.children
 *
 */
export default function NoticeBlock({ colorBackground, children, ...restProps }) {
    const { t } = useTranslation();

    const [isVisible, setIsVisible] = useState(true);

    const acceptTelemetry = () => {
        localStorage.setItem('telemetry', 'accepted');
        setIsVisible(!isVisible);
    };

    return localStorage.telemetry !== 'accepted' && isVisible
        ? (
            <NoticeContainer  >
                <NoticeFrame>
                    <NoticeContent>
                        <Typography.BodyL>{t('cookies_banner.title')}</Typography.BodyL>
                        <Typography.Body>{t('cookies_banner.content')}</Typography.Body>
                        {children}
                        <PartnerButton
                            color="#FFFFFF"
                            backgroundColor={colorBackground}>
                            <Button onClick={acceptTelemetry} {...restProps} >
                                {t('cookies_banner.cta')}
                            </Button>
                        </PartnerButton>
                    </NoticeContent>
                </NoticeFrame>
            </NoticeContainer>
        )
        : null;
}

NoticeBlock.propTypes = {
    children: propTypes.any,
    colorBackground: propTypes.string.isRequired,
};
