import {
    logger,
} from '@testamento/design-system/dist/lib';
import store from '.';
import {
    AuthState,
} from '../constants';
import parameters from '../parameters.json';

import {
    SWITCH_USER,
    SET_PARTNER,
    SET_CONFIG,
    TOKEN_VALIDATED,
    TOKEN_INVALIDATED,
    PREPARE_SIGNUP_CODE_VALIDATION,
    DISPLAY_SIGN_ERROR_MESSAGE,
    RESET_SIGN_ERROR_MESSAGE,
    DISPLAY_LOADER,
    HIDE_LOADER,
} from './action.types';

const debug = logger('planner:action');


export const signError = (message) => (dispatch) => {
    dispatch({
        type: DISPLAY_SIGN_ERROR_MESSAGE,
        user: null,
        message,
    });
};

export const resetSignError = () => (dispatch) => {
    dispatch({
        type: RESET_SIGN_ERROR_MESSAGE,
    });
};

export const signOut = () => (dispatch) => {
    dispatch({
        type: 'SWITCH_USER',
        user: null,
    });
};

export const getValidToken = (partnerName, token, hubDispatch) => (dispatch) => {
    const url = `${parameters.public_api_url}/partner/validation/${partnerName}/bytoken/${token}`;

    fetch(url, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        },
        mode: 'cors',
    })
        .then(response => (response.status === 200)
            ? response.json().then((json) => json.validation
                ? (() => {
                    localStorage.setItem('givenToken', token);
                    store.dispatch(resetAuth(AuthState.SignUp, hubDispatch));
                    return dispatch({
                        type: TOKEN_VALIDATED,
                    });
                })()
                : dispatch({
                    type: TOKEN_INVALIDATED,
                    error: json.error,
                })
            )
            : dispatch({
                type: TOKEN_INVALIDATED,
                error: 'UNACCESSIBLE_ENDPOINT',
            })
        )
        .catch(error => {
            debug({ error });
            return dispatch({
                type: TOKEN_INVALIDATED,
                error: error.message,
            });
        });
};

export const validateUser = async (accessToken) => {
    const url = `${parameters.public_api_url}/user/validate/session`;
    return new Promise((resolve, reject) => {
        fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': accessToken,
            },
            mode: 'cors',
        }).then(
            (options) => {
                switch (options.status) {
                    case 401: {
                        reject(false);
                        break;
                    }
                    case 200: {
                        resolve(true);
                        break;
                    }
                    default: {
                        reject(false);
                        break;
                    }
                }
            }
        ).catch(() => {
            reject(false);
        });
    });
};

export const setPartner = (partner) => {
    debug('partner : ' + partner);
    return {
        type: SET_PARTNER,
        partner,
    };
};

export const setConfig = (config) => {
    debug('Global configuration : ' + config);
    return {
        type: SET_CONFIG,
        config,
    };
};

export const switchUser = (user) => {
    debug('switchUser : ' + user);
    return {
        type: SWITCH_USER,
        user,
    };
};

export const prepareSignupCodeValidation = (username, password) => {
    store.dispatch(switchUser({ username }));
    debug('prepare signup code validation : ');
    return {
        type: PREPARE_SIGNUP_CODE_VALIDATION,
        payload: {
            username,
            password,
        },
    };
};

export const reSign = (user, hubDispatch) => {
    store.dispatch(switchUser(user));
    store.dispatch(resetSignError());
    hubDispatch('UI Auth', {
        event: 'AuthStateChange',
        message: AuthState.ConfirmSignUp,
    });
    return {
        type: AuthState.ConfirmSignUp,
        authState: AuthState.ConfirmSignUp,
    };
};

export const resetAuth = (targetedState, hubDispatch) => {
    store.dispatch(resetSignError());
    hubDispatch('UI Auth', {
        event: 'AuthStateChange',
        message: targetedState,
    });
    return {
        type: targetedState,
        authState: targetedState,
    };
};

export const signIn = (hubDispatch) => {
    store.dispatch(resetSignError());
    hubDispatch('UI Auth', {
        event: 'AuthStateChange',
        message: AuthState.SignIn,
    });
    return {
        type: AuthState.SignIn,
        authState: AuthState.SignIn,
    };
};

export const signUp = (hubDispatch) => {
    store.dispatch(resetSignError());
    hubDispatch('UI Auth', {
        event: 'AuthStateChange',
        message: AuthState.SignUp,
    });
    return {
        type: AuthState.SignUp,
        authState: AuthState.SignUp,
    };
};

export const confirmSignUp = (hubDispatch) => {
    store.dispatch(resetSignError());
    hubDispatch('UI Auth', {
        event: 'AuthStateChange',
        message: AuthState.ConfirmSignUp,
    });
    return {
        type: AuthState.ConfirmSignUp,
        authState: AuthState.ConfirmSignUp,
    };
};

export const forgotPassword = (hubDispatch) => {
    store.dispatch(resetSignError());
    hubDispatch('UI Auth', {
        event: 'AuthStateChange',
        message: AuthState.ForgotPassword,
    });
    return {
        type: AuthState.ForgotPassword,
        authState: AuthState.ForgotPassword,
    };
};

export const resetPassword = (username, hubDispatch) => {
    store.dispatch(switchUser({ username }));
    store.dispatch(resetSignError());
    hubDispatch('UI Auth', {
        event: 'AuthStateChange',
        message: AuthState.ResetPassword,
    });
    return {
        type: AuthState.ResetPassword,
        authState: AuthState.ResetPassword,
    };
};

export const displayLoader = () => (dispatch) => {
    dispatch({
        type: DISPLAY_LOADER,
    });
};

export const hideLoader = () => (dispatch) => {
    dispatch({
        type: HIDE_LOADER,
    });
};
