//@ts-check

import parameters from '../../parameters.json';
const {
    PasswordPolicy,
} = parameters;
const {
    MinimumLength,
    RequireLowercase,
    RequireUppercase,
    RequireNumbers,
    RequireSymbols,
} = PasswordPolicy;

export const mandatoryCheckRules = (value) => !!value;
const anyCharacterWrappedByQuote='(\\".+\\")';
const nonPunctuation = '[^<>()[\\]\\\\.,;:\\s@"]+';
const userMailTester = `((${nonPunctuation}(\\.${nonPunctuation})*)|(${anyCharacterWrappedByQuote}))`;
const IPV4MailServer = '(\\[[0-9]{1,3}(?:\\.[0-9]{1,3}){3}\\])';
const mailServerName = '(([-a-zA-Z0-9]+\\.)+[a-zA-Z]{2,})';
// eslint-disable-next-line no-useless-escape
export const signUpEmailRule = (value) => (new RegExp(`^${userMailTester}@(${IPV4MailServer}|${mailServerName})$`)).test(value);


// Above rules has been derived from https://docs.aws.amazon.com/cognito/latest/developerguide/user-pool-settings-policies.html
const lowercaseTester = 'a-z';
const uppercaseTester = 'A-Z';
const numberTester = '\\d';
const symbolTester = '^$*.[\\]{}()?"!@#%&/,><\':;|_~`';

const wrapCharacterClass = (characterClass) => `[${characterClass}]`;
/**
 *
 * @param {Number} passwordMinimalLength
 * @param {Boolean} passwordRequireLowercase
 * @param {Boolean} passwordRequireUppercase
 * @param {Boolean} passwordRequireNumbers
 * @param {Boolean} passwordRequireSymbols
 */
function *passwordPolicyGenerator (
    passwordMinimalLength,
    passwordRequireLowercase,
    passwordRequireUppercase,
    passwordRequireNumbers,
    passwordRequireSymbols
) {
    if(passwordRequireLowercase) {
        yield (value) => new RegExp(wrapCharacterClass(lowercaseTester)).test(value);
    }
    if(passwordRequireUppercase) {
        yield (value) => new RegExp(wrapCharacterClass(uppercaseTester)).test(value);
    }
    if(passwordRequireNumbers) {
        yield (value) => new RegExp(wrapCharacterClass(numberTester)).test(value);
    }
    if(passwordRequireSymbols) {
        yield (value) => new RegExp(wrapCharacterClass('\\'+symbolTester)).test(value);
    }
    if(
        passwordMinimalLength &&
        typeof passwordMinimalLength === 'number' &&
        Math.trunc(passwordMinimalLength) === passwordMinimalLength
    ) {
        yield (value) => new RegExp(
            `^${wrapCharacterClass([
                '+=',
                lowercaseTester,
                uppercaseTester,
                numberTester,
                symbolTester
            ].join())}{${passwordMinimalLength},}$`
        ).test(value);
    }
}

export const signUpPasswordRule = (value) => Array.from(passwordPolicyGenerator(
    MinimumLength,
    RequireLowercase,
    RequireUppercase,
    RequireNumbers,
    RequireSymbols
)).map(tester => tester(value)).reduce((result, ruleMatch) => result && ruleMatch, true);

export const confirmationCodeRule = (value) => /^\d{6}$/.test(value);
