import React, {
    useState, useEffect, useContext, createContext,
} from 'react';
import propTypes from 'prop-types';

const NetworkStatusContext = createContext(true);
/**
 * NetworkStatusContext should be used to listen to the browser's network status.
 * This function listen the `navigator` object to define the network status.
 * It returns a Boolean value of `True` if it's online, `False` if it's offline.
 *
 * @param {Object} props
 * @param {React.ReactNode} props.children
 * @returns
 */
export const NetworkStatusProvider = ({ children }) => {
    const [networkStatus, setNetworkStatus] = useState(true);

    useEffect(() => {
        window.addEventListener('offline', () => {
            setNetworkStatus(false);
        });
        window.addEventListener('online', () => {
            setNetworkStatus(true);
        });

        return () => {
            window.removeEventListener('offline', () => {
                setNetworkStatus(false);
            });
            window.removeEventListener('online', () => {
                setNetworkStatus(true);
            });
        };
    }, []);

    return (
        <NetworkStatusContext.Provider value={networkStatus} >
            {children}
        </NetworkStatusContext.Provider>
    );
};


export const useNetworkStatus = () => {
    const store = useContext(NetworkStatusContext);
    return store;
};


NetworkStatusProvider.propTypes = {
    children: propTypes.any,
};
