//@ts-check

import React from 'react';
import propTypes from 'prop-types';
import {
    useTranslation,
} from 'react-i18next';
import {
    useMatomo,
} from '@jonkoops/matomo-tracker-react';

import DefaultError from './DefaultError';


/**
 *
 * @param {*} props
 * @returns
 */
export default function SignOutError({ colorBackground }) {
    const { t } = useTranslation((['errors']));
    const { trackEvent } = useMatomo();
    trackEvent({ category: 'SignOutErrorPage', action: 'signout-error' });

    return (
        <DefaultError
            title={t('signout_error_page.title')}
            message={t('signout_error_page.content')}
            action={t('signout_error_page.cta')}
            colorBackground={colorBackground} />
    );
}

SignOutError.propTypes = {
    colorBackground: propTypes.string,
};
