import React from 'react';
import propTypes from 'prop-types';
import {
    decode,
} from 'he';
import {
    useTranslation,
} from 'react-i18next';
import {
    useSelector,
} from 'react-redux';
import {
    Typography,
    Flashbag,
} from '@testamento/design-system';
import {
    logger,
    FragmentStringToJSX,
} from '@testamento/design-system/dist/lib';
import styled, {
    css,
} from 'styled-components';
import {
    Cognito,
} from '@testamento/react-components';

import {
    AuthState,
} from '../../constants';
import {
    CurrentSignFormWithWrapper,
} from './Panel';
import ForgotPassword from './forgot-password';
import ResetPassword from './reset-password';
import ConfirmSignUp from './confirm-signup';
import store from '../../store';
import {
    signIn,
    signUp,
} from '../../store/actions';

const paddedBlock = css`
    margin: 0 0 2rem;
`;
const {
    CognitoSignup,
    useCognitoHubDispatch,
} = Cognito;

const b = ({ children }) => {
    return (
        <strong>{children}</strong>
    );
};
b.propTypes = {
    children: propTypes.node,
};
const AuthContextDescription = styled(Typography.Body)`${paddedBlock}`;

const ErrorFlashBag = styled(Flashbag)`${paddedBlock}`;
const debug = logger('planner:auth');
const SignActionError = ({ isLocked }) => {
    const { t } = useTranslation();
    const signActionError = useSelector(state => state.signActionError);
    return (signActionError
        ? (
            <ErrorFlashBag
                background="danger"
                title={<FragmentStringToJSX
                    content={decode(t(signActionError))}
                    mappings={{
                        b,
                    }}
                />}
                {...{ isLocked }}
            />
        )
        : null
    );
};

SignActionError.propTypes = {
    isLocked: propTypes.bool,
};

const AuthPanelWithoutSignup = () => {
    const authState = useSelector(state => state.authState);

    return (
        <React.Fragment>
            {debug('%cAuthPanelWithoutSignup', 'color: papayawhip;font-weight: bold; background-color: #000000;', {
                authState,
                message: 'display standard signin',
            })}
            {authState === AuthState.SignIn
                ? <CurrentSignFormWithWrapper />
                : null
            }
        </React.Fragment>
    );
};

const AuthPanelWithSignup = () => {
    const authState = useSelector(state => state.authState);
    const { t } = useTranslation();
    const hubDispatch = useCognitoHubDispatch();

    return (
        <React.Fragment>
            {(!authState || authState === AuthState.SignIn || authState === AuthState.ForgotPassword || authState === AuthState.ResetPassword
                ? <AuthContextDescription>
                    {t('auth.signup.no_account')}&nbsp;
                    <Typography.Link href='#/' onClick={() => store.dispatch(signUp(hubDispatch))}>
                        {t('auth.signup.subscribe_action')}
                    </Typography.Link>
                </AuthContextDescription>
                : null
            )}
            {authState === AuthState.SignUp ? (
                <AuthContextDescription>
                    {t('auth.signin.have_account')}&nbsp;
                    <Typography.Link href='#/' onClick={() => store.dispatch(signIn(hubDispatch))}>
                        {t('auth.signin.connect_action')}
                    </Typography.Link>
                </AuthContextDescription>
            ) : null}
            {authState === AuthState.SignIn || authState === AuthState.SignUp
                ? <CurrentSignFormWithWrapper />
                : null
            }
        </React.Fragment>
    );
};
export const AuthComponent = () => {
    const authState = useSelector(state => state.authState);
    const config = useSelector(state => state.config);

    const { global = {} } = config;
    const { colorBackground } = global;
    return <CognitoSignup>
        {(validated) => {
            return (
                <React.Fragment>
                    <SignActionError
                        isLocked={validated === false}
                    />
                    {validated || (global && global.deactivateToken)
                        ? <AuthPanelWithSignup />
                        : <AuthPanelWithoutSignup />
                    }
                    {authState === AuthState.ForgotPassword && <ForgotPassword {...{ colorBackground }} />}
                    {authState === AuthState.ResetPassword && <ResetPassword {...{ colorBackground }} />}
                    {authState === AuthState.ConfirmSignUp && <ConfirmSignUp {...{ colorBackground }} />}
                </React.Fragment>
            );
        }}
    </CognitoSignup>;
};
