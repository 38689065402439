//@ts-check

import React from 'react';
import propTypes from 'prop-types';
import {
    decode,
} from 'he';
import {
    Grid,
    Typography,
    Button,
} from '@testamento/design-system';
import {
    FragmentStringToJSX,
} from '@testamento/design-system/dist/lib';
import {
    HeadingContainer,
    TitleContainer,
    BodyLight,
    VideoContainer,
    VideoWrapper,
    VideoElement,
    MarketingSection,
    FormFrame,
    FormContainer,
    GridCol,
    AlignedContainer,
} from './Landing/styles';
import {
    PartnerButton,
} from './Block/Styling';
import {
    useTranslation,
} from 'react-i18next';
import {
    mappings,
} from './Landing/translation';
import FooterBlock from './Block/FooterBlock';
import {
    definedTranslationKey,
} from '../utils/content';

/**
 *
 * @param {Object} props
 * @param {string} props.title
 * @param {string} props.details
 * @param {string} props.url
 */
function VideoBlock({
    smallLayout,
    title,
    details,
    url,
} = {}) {

    return (
        <>
            {smallLayout
                ?
                <>
                    <VideoContainer data-tuto="video">
                        <VideoWrapper >
                            <VideoElement
                                src={url}
                                frameborder="0"
                                allow="autoplay; fullscreen"
                                allowfullscreen
                            />
                        </VideoWrapper>
                        <TitleContainer >
                            <Typography.Body>{title}&nbsp;</Typography.Body>
                            <BodyLight>{details}</BodyLight>
                        </TitleContainer>
                    </VideoContainer>
                </>
                :
                <>
                    <HeadingContainer>
                        <Typography.HeadingMd>{title}&nbsp;</Typography.HeadingMd>
                        <Typography.HeadingXxs>{details}</Typography.HeadingXxs>
                    </HeadingContainer>
                    <VideoContainer data-tuto="video">
                        <VideoWrapper >
                            <VideoElement
                                src={url}
                                frameborder="0"
                                allow="autoplay; fullscreen"
                                allowfullscreen
                            />
                        </VideoWrapper>
                    </VideoContainer>
                </>
            };

        </>
    );
}

VideoBlock.propTypes = {
    smallLayout: propTypes.bool,
    title: propTypes.string,
    details: propTypes.string,
    url: propTypes.string,
};


const SignFormWrapper = ({ children }) => (
    <FormFrame>
        <FormContainer>
            {children}
        </FormContainer>
    </FormFrame>
);

SignFormWrapper.propTypes = {
    children: propTypes.node,
};



const MappedStrong = ({ children }) => (
    <strong>
        {children}
    </strong>
);

MappedStrong.propTypes = {
    children: propTypes.node,
};

const scrollToLogin = () => {
    window.scrollTo(0, 0);
};

export const LandingHeader = () => {
    const { t } = useTranslation();

    return (definedTranslationKey(t, 'landing.content.fragment_header')
        ? (
            <FragmentStringToJSX
                content={decode(t('landing.content.fragment_header'))}
                mappings={mappings}
            />
        )
        : null
    );
};

/**
 *
 * @returns
 */
function ContentPreamble() {
    const { t } = useTranslation();

    return (
        <Grid.Row>
            <GridCol size={1}>
                <FragmentStringToJSX
                    content={decode(t('landing.content.fragment_preamble'))}
                    mappings={mappings}
                />
            </GridCol>
        </Grid.Row>
    );
}

/**
 *
 * @param {import('../..').LandingContentProps} props
 * @returns
 */
function ContentDescription({ global }) {
    const { t } = useTranslation();

    return (
        <Grid.Row>
            <GridCol size={1}>
                <AlignedContainer>
                    <FragmentStringToJSX
                        content={decode(t(
                            'landing.content.fragment_description',
                            {
                                partner: global.nameInLabel,
                            }
                        ))}
                        mappings={mappings}
                    />
                </AlignedContainer>
            </GridCol>
        </Grid.Row>
    );
}
ContentDescription.propTypes = {
    global: propTypes.shape({
        nameInLabel: propTypes.string,
    }),
};

/**
 *
 * @returns
 */
function ContentVideo() {
    const { t } = useTranslation();

    return (
        <Grid.Row>
            <Grid.Col size={1}>
                <VideoBlock
                    title={t('landing.video.title')}
                    details={t('landing.video.details')}
                    url={t('landing.video.url')}
                />
            </Grid.Col>
        </Grid.Row>
    );
}

/**
 *
 * @param {import('../..').LandingContentProps} props
 * @returns
 */
function ContentCallToAction({
    colorBackground,
}) {
    const { t } = useTranslation();

    return (
        <Grid.Row>
            <Grid.Col size={1}>
                <PartnerButton
                    backgroundColor={colorBackground}
                    color="#FFFFFF"
                >
                    <Button
                        as="a"
                        onClick={scrollToLogin}
                    >
                        {t('landing.cta')}
                    </Button>
                </PartnerButton>
            </Grid.Col>
        </Grid.Row>
    );
}
ContentCallToAction.propTypes = {
    colorBackground: propTypes.string,
};

/**
 *
 * @param {function} t
 */
function* generateLandingContent(t) {
    if (definedTranslationKey(t, 'landing.content.fragment_preamble')) {
        yield ContentPreamble;
    }
    if (definedTranslationKey(t, 'landing.content.fragment_description')) {
        yield ContentDescription;
    }
    if (
        definedTranslationKey(t, 'landing.video.title') &&
        definedTranslationKey(t, 'landing.video.details') &&
        definedTranslationKey(t, 'landing.video.url')
    ) {
        yield ContentVideo;
    }
    if (definedTranslationKey(t, 'landing.cta')) {
        yield ContentCallToAction;
    }
}

/**
 *
 * @param {import('../..').LandingContentProps} props
 * @returns
 */
export function LandingContent({
    global,
    colorBackground,
}) {
    const { t } = useTranslation();
    const landingContentMap = [...generateLandingContent(t)];

    return (landingContentMap.length > 0
        ? <MarketingSection>
            <Grid maxFreeze={'true'}>
                {landingContentMap.map((Content, index) => (
                    <Content
                        key={index}
                        {...{
                            global,
                            colorBackground,
                        }}
                    />
                ))}
            </Grid>
        </MarketingSection>
        : null
    );
}
LandingContent.propTypes = {
    global: propTypes.shape({
        nameInLabel: propTypes.string,
    }),
    colorBackground: propTypes.string,
};

export const LandingFooter = () => {
    const { t } = useTranslation();
    return (definedTranslationKey(t, 'footer')
        ? (
            <FooterBlock
                content={t('footer', { returnObjects: true })}
            />
        )
        : null
    );
};
