//@ts-check

import React, {
    useEffect,
} from 'react';
import propTypes from 'prop-types';
import {
    Cognito,
} from '@testamento/react-components';
import {
    useSelector,
} from 'react-redux';
import {
    logger,
} from '@testamento/design-system/dist/lib';


import trackActivity from '../utils/trackActivity';
import trackEventService from '../utils/trackEventService';
import {
    getUserAuthorization,
} from '../utils/user';
import parameters from '../parameters.json';
import LoaderReact from '../components/LoaderReact.jsx';
import {
    AuthState,
} from '../constants';
const {
    CognitoInteraction,
    useCognitoValuesWatcher,
} = Cognito;

const checkLogout = () => '/logout' === window.location.pathname;
const productLandingUri = {
    secure: '/profile/show ',
};
const debug = (topic, message)=>{
    if (typeof message === 'object') {
        logger(topic)('%capp-redirection','color: blue; font-weight:bold;',JSON.stringify(message, null, 4));
    }
    else {
        logger(topic)('%capp-redirection','color: blue; font-weight:bold;',message);
    }
};


const fetchRedirection = ({
    fetchUrl,
    urlConstructor,
    AuthorizationToken,
    AuthSignout,
}) => {
    fetch(
        fetchUrl,
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': AuthorizationToken,
            },
            mode: 'cors',
            body: JSON.stringify({
                application: 'secure',
                returnURL: `https://${window.location.host}/logout`,
            }),
        }
    )
        .then(options => (options.status === 200)
            ? (() => {
                options.json().then((body) => {
                    const redirectionUri = urlConstructor(body);
                    debug('planner:app-redirection', { redirectionUri });
                    localStorage.setItem(
                        'secureSession',
                        true
                    );
                    window.location.href = redirectionUri;
                }).catch(AuthSignout);
            })()
            : (()=> {
                debug('planner:app-redirection',{ options });
                options.json()
                    .then(AuthSignout)
                    .catch(AuthSignout);
            })()
        )
        .catch(AuthSignout);
};

const PostCognitoBehavior = (user, AuthSignout, backPath) => {
    const AuthorizationToken = getUserAuthorization(user);
    debug('planner:post-cognito-behavior',{ user });
    debug('planner:post-cognito-behavior',{ signInUserSession: user.signInUserSession });
    debug('planner:post-cognito-behavior',{ idToken: user.signInUserSession.idToken });
    debug('planner:post-cognito-behavior',{ AuthorizationToken });

    fetchRedirection({
        AuthSignout,
        fetchUrl: `${parameters.public_api_url}/user/jwt/token`,
        urlConstructor: (data) => {
            const productName = 'secure';
            const url = `https://${parameters.testamento_secure_domain}${backPath}?bearer=${data.token}`;

            debug({ testamentoAppRedirectionUrlConstructor: url });
            const objectUrl = new URL(url);
            debug({ futureRedirection: objectUrl.origin + (productLandingUri[productName]
                ? productLandingUri[productName]
                : '/'
            ), currentRedirection: url });
            return url;
        },
        AuthorizationToken,
    });
};

export const redirectToSecureAction = (user, AuthSignout, backPath) => {
    if (checkLogout()) {
        AuthSignout();
    } else {
        PostCognitoBehavior(user, AuthSignout, backPath);
    }
};
const amplifyEvent = {
    [AuthState.ConfirmSignUp]: 'signup',
    [AuthState.SignIn]: 'signin',
    [AuthState.ResetPassword]: 'reset password',
};
const Redirection = ({ Auth, backPath })=> {
    const {
        user,
    } = useCognitoValuesWatcher(['user'], 'userDispatch');

    const authState = useSelector(state => state.authState);
    const AuthSignout = () => {
        Auth.signOut({ global: true }).then(
            () => {/** noop */}
        ).catch(
            error => debug('planner:contexts:secure:error-signing-out', error)
        );
    };
    useEffect(() => {
        debug(
            'planner:contexts:secure:redirect',
            {
                redirectToSecureAction: {
                    user,
                    Auth,
                },
            }
        );

        if(typeof user !== 'string') {
            Promise.allSettled([
                trackActivity(
                    getUserAuthorization(user),
                    amplifyEvent[authState]
                ),
                trackEventService(
                    getUserAuthorization(user),
                    amplifyEvent[authState]
                )
            ])
                .finally(
                    () => {
                        redirectToSecureAction(user, AuthSignout, backPath);
                    }
                );
        }
    }, [user, Auth]);

    return (
        <LoaderReact context="redirection to secure in progress" />
    );
};
Redirection.propTypes = {
    Auth: propTypes.object,
    backPath: propTypes.string,
};

/**
 *
 * @returns ReactElement
 */
export default function SecureRedirection({ backPath }) {
    return (
        <CognitoInteraction>
            {(Auth) => <Redirection {...{ Auth, backPath }} />}
        </CognitoInteraction>
    );
}
SecureRedirection.defaultProps = {
    backPath: '/fr/myprofile',
};
SecureRedirection.propTypes = {
    backPath: propTypes.string,
};
