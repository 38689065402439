
const selfClosing = (rawContent) => {
    const tagsDetails = rawContent.split(/(\s|\n)+/);
    return ({
        renderer: tagsDetails[0],
        attributes: rawContent
            .slice(tagsDetails[0].length, rawContent.length - tagsDetails[0].length -1),
    });
};
const closing = (rawContent) => {
    const tagsDetails = rawContent.split(/(\s|\n)+/);
    return ({
        renderer: tagsDetails[0].slice(1),
    });
};
const opening =(rawContent) => {
    const tagsDetails = rawContent.split(/(\s|\n)+/);
    return ({
        renderer: tagsDetails[0],
        attributes: rawContent
            .slice(
                tagsDetails[0].length,
                rawContent.length - tagsDetails[0].length
            ),
    });
};
export const parser = (simpleXmlFragment) => {
    const start = simpleXmlFragment.split('<');

    const { chunk } = start.reduce((context, current) => ({
        current: context.current + 1 + current.length,
        chunk: context.chunk.concat([{
            offset: context.current,
            content: current,
        }]),
    }), {
        current: 0,
        chunk: [],
    });

    const indexedContent = chunk.map((item, i) => {
        if (item.content) {
            const stop = item.content.split('>');
            if(i === 0 && stop.length === 1 ) {
                return [{
                    text: item.content,
                    start: item.offset,
                }];
            }
            if (stop.length !== 2) {
                throw new Error('unbalanced tag');
            }
            if (stop[0] === '') {
                throw new Error('empty tag');
            }

            if (stop[1] === '') {
                return [{
                    rawTag: stop[0],
                    start: item.offset,
                }];
            }
            return [{
                rawTag: stop[0],
                start: item.offset,
            }, {
                text: stop[1],
                start: item.offset + stop[0].length + 1,
            }];
        }
        return [];
    }).reduce((flattenedItems, current) => flattenedItems.concat(current),[]);

    const markupState = {
        stack: [{ childs: [] }],
    };

    indexedContent.forEach((current) => {
        if(current){
            if(current.text) {
                markupState.stack[markupState.stack.length-1].childs.push(current.text);
            } else if(current.rawTag.slice(-1) === '/') {
                const {
                    renderer,
                    attributes,
                } = selfClosing(current.rawTag);

                if (attributes !== '') {
                    throw new Error('Simple xml tag does\'t have attributes');
                }

                markupState.stack[markupState.stack.length-1].childs.push({ renderer });
            } else if(current.rawTag.slice(0, 1) === '/') {
                const {
                    renderer,
                } = closing(current.rawTag);
                const cumulatedChilds = [];
                for (let i = markupState.stack.length -1; i > -1; i--) {
                    if(markupState.stack[i].renderer === renderer) {
                        markupState.stack[i].childs = markupState.stack[i].childs.concat(cumulatedChilds);
                        markupState.stack[i-1].childs.push(
                            markupState.stack[i]
                        );
                        markupState.stack.splice(i, markupState.stack.length - i);

                        break;
                    } else {
                        // Auto Closing tag not closed in stack
                        cumulatedChilds.splice.apply(cumulatedChilds, [
                            0,
                            0,
                            { renderer: markupState.stack[i].renderer },
                            ...markupState.stack[i].childs
                        ]);
                    }
                }
            } else {
                const {
                    renderer,
                    attributes,
                } = opening(current.rawTag);

                if (attributes !== '') {
                    throw new Error('Simple xml tag does\'t have attributes');
                }

                markupState.stack.push({
                    renderer,
                    childs: [],
                });
            }
        }
    });
    if (markupState.stack.length ===1) {
        return markupState.stack[0].childs;
    }
    throw new Error('can\'t parse given simple xml fragment');
};
