
import React from 'react';
import propTypes from 'prop-types';
import {
    FormControl,
} from '@testamento/design-system';

import {
    signUpPasswordRule,
    mandatoryCheckRules,
    confirmationCodeRule,
} from '../../../lib/validator';

import {
    FieldLabel,
    FieldWrapper,
    FormCell,
    ErrorRenderer,
} from './styles';

import Remote, {
    useRemoteState,
} from '../../Forms';
import {
    NullComponent,
} from '../../../utils/react';


const {
    Field,
    ControlledInput,
    DefaultInputField,
    TextInput,
    useValidationErrors,
    useValidationState,
    // userEmail,
    useFieldsError,
    useFieldsValue,
} = FormControl;

const ResetPassword = ({
    confirmationCodeLabelMessage,
    confirmationCodePlaceholderMessage,
    confirmationCodeErrorMessage,
    newUserPasswordLabelMessage,
    newUserPasswordPlaceholderMessage,
    newUserPasswordErrorMessage,
    PreForm,
    PostForm,
}) => {
    const dispatch = useRemoteState();

    return (
        <FormControl>
            <PreForm formDispatch={dispatch}>
                <Field Element={FieldWrapper}>
                    <FieldLabel as="p">{confirmationCodeLabelMessage}</FieldLabel>
                    <FormCell>
                        <ControlledInput
                            ControlledElement={DefaultInputField}
                            name="confirmationCode"
                            type="number"
                            placeholder={confirmationCodePlaceholderMessage}
                            value=""
                            autoWidth={'true'}
                            validations={[
                                (value) => (confirmationCodeRule(value)
                                    ? ''
                                    : confirmationCodeErrorMessage
                                )
                            ]}
                        />
                    </FormCell>
                    <ErrorRenderer name="confirmationCode">
                        {confirmationCodeErrorMessage}
                    </ErrorRenderer>
                </Field>
            </PreForm>
            <Field Element={FieldWrapper}>
                <FieldLabel as="p">{newUserPasswordLabelMessage}</FieldLabel>
                <FormCell>
                    <ControlledInput
                        ControlledElement={TextInput.Password}
                        name="newUserPassword"
                        autoWidth={'true'}
                        placeholder={newUserPasswordPlaceholderMessage}
                        validations={[
                            (value) => (mandatoryCheckRules(value)
                                ? ''
                                : newUserPasswordErrorMessage
                            ),
                            (value) => (signUpPasswordRule(value)
                                ? ''
                                : newUserPasswordErrorMessage
                            )
                        ]}
                    />
                </FormCell>
                <ErrorRenderer name="newUserPassword" description={true}>
                    {newUserPasswordErrorMessage}
                </ErrorRenderer>
            </Field>
            <PostForm  formDispatch={dispatch} />
        </FormControl>
    );
};
ResetPassword.defaultProps = {
    PreForm: NullComponent,
    PostForm: NullComponent,
};
ResetPassword.propTypes = {
    PreForm: propTypes.func,
    PostForm: propTypes.func,

    initialUserEmailValue: propTypes.string,
    confirmationCodeLabelMessage: propTypes.string,
    confirmationCodePlaceholderMessage: propTypes.string,
    confirmationCodeErrorMessage: propTypes.string,
    newUserPasswordLabelMessage: propTypes.string,
    newUserPasswordPlaceholderMessage: propTypes.string,
    newUserPasswordErrorMessage: propTypes.string,
};

/**
 *
 * @param {*} param0
 */
export default function ResetPasswordWrapper ({ children, ...props }) {
    return (
        <Remote>
            <ResetPassword {...props}>
                {children}
            </ResetPassword>
        </Remote>
    );
}
ResetPasswordWrapper.propTypes = {
    children: propTypes.node,
};

export {
    useValidationErrors,
    useValidationState,
    useFieldsError,
    useFieldsValue
};
