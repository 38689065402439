import styled from 'styled-components';
import {
    Typography,
    Grid,
} from '@testamento/design-system';

const colorGrey = '#979797';

export const HeadingContainer = styled.div`
margin: 9.5rem 0 2.3rem 0;
    display: flex;
    align-items: flex-end;
    flex-wrap: wrap;

    h6 {
        color: ${colorGrey};
    }

    @media(max-width: 1056px) {
        h3 {
            margin-bottom: 1.3rem;
        }
    }
`;
export const TitleContainer = styled.div`
    margin-top: 2.3rem;
    display: flex;
    align-items: flex-end;
    flex-wrap: wrap;
`;

export const BodyLight = styled(Typography.Body)`
    color: ${colorGrey};
`;

export const VideoContainer = styled.div`
    width: 100%;
    margin-top: 5.6rem;
`;

export const VideoWrapper = styled.div`
    padding: 56.25% 0 0 0;
    position: relative;
`;

export const VideoElement = styled.iframe`
    position: absolute;
    top: 0;
    left:0;
    width:100%;
    height:100%;
    border: none;
`;

export const MarketingSection = styled.section`
    margin-top: 40px;
    padding-top: 50px;
    background-color: #FFFFFF;
    position: relative;
`;

export const FormFrame = styled.div`
    display: flex;
    justify-content: center;
`;

export const FormContainer = styled.div`
    text-align: center;
    background-color: white;
    border-radius: .4rem;
    padding: 5.6rem 3.6rem;
    max-width: 41.4rem;
    width: 100%;
`;

export const GridCol = styled(Grid.Col)`
    padding: 0 20px;
    margin: 0;
`;

export const AlignedContainer = styled.div`
    text-align: ${({ align } = { align: 'left' }) => (align)};
`;
