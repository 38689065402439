//@ts-check

import React from 'react';
import {
    useTranslation,
} from 'react-i18next';
import propTypes from 'prop-types';
import {
    useMatomo,
} from '@jonkoops/matomo-tracker-react';

import {
    ActionsWrapper,
} from './styles';
import {
    Page,
    Typography,
    Button,
} from '@testamento/design-system';
import {
    PartnerButton,
} from '../components/Block/Styling';


/**
 *
 * @param {*} props
 * @returns
 */
export default function NetworkError({ colorBackground }) {
    const { t } = useTranslation((['errors']));
    const { trackEvent } = useMatomo();
    trackEvent({ category: 'NetworkErrorPage', action: 'network-error' });

    return (
        <Page
            title={t('network_error_page.title')}
            pageType='event'>
            <Typography.BodyL>
                {t('network_error_page.content')}
            </Typography.BodyL>
            <ActionsWrapper>
                <PartnerButton color="#FFFFFF" backgroundColor={colorBackground}>
                    <Button onClick={() => window.location.reload()} >
                        {t('network_error_page.cta')}
                    </Button>
                </PartnerButton>
            </ActionsWrapper>
        </Page>
    );
}

NetworkError.propTypes = {
    colorBackground: propTypes.string,
};
