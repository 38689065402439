import React from 'react';
import styled from 'styled-components';
import propTypes from 'prop-types';

import {
    applyGapOn,
} from '../../../lib/styled';

const ButtonContentWrapper = styled.span`
    display: flex;
    margin: 0 auto;
`;

const ElementWrapper = styled.span`
    flex-grow: initial;
    line-height: 0;
    align-self: stretch;
    display: flex;
    ${(props) => applyGapOn(props, 'margin-right')}
`;

const TextWrapper = styled.span`
    flex-grow: initial;
    line-height: 1.2em;
    text-align: left;
    ${(props) => applyGapOn(props, 'margin-left')}
`;


// This should be deduced through figma spacing and button extractor in src/bin/figma
const gapValue = '1.3rem';

/**
 *
 * @param {Object} props
 * @param {ReactNode} props.children
 * @param {ReactElement} props.Component
 * @param {Object} props.styles
 * @returns
 */
export default function ButtonLike ({
    children,
    Component,
    styles,
    ...restProps
}) {
    return <Component
        {...Object.assign(
            { style: styles },
            restProps
        )}
    >
        <ButtonContentWrapper>
            {React.Children.map(children, (child, i) => {
                if(typeof child === 'string'){
                    return (
                        <TextWrapper
                            gap={(i !== children.length - 1 && children.length !== undefined
                                ? gapValue
                                : undefined
                            )}
                        >
                            {child}
                        </TextWrapper>
                    ) ;
                }
                return (
                    <ElementWrapper
                        gap={(i !== children.length-1 && children.length !== undefined
                            ? gapValue
                            : undefined
                        )}
                    >
                        {React.cloneElement(
                            child,
                            {
                                flexCentered: true,
                            },
                            child.children
                        )}
                    </ElementWrapper>
                );
            })}
        </ButtonContentWrapper>
    </Component>;
}
ButtonLike.propTypes = {
    children: propTypes.node,
    Component: propTypes.element,
    styles: propTypes.object,
};
