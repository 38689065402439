
import React from 'react';
import propTypes from 'prop-types';
import {
    FormControl,
    Grid,
} from '@testamento/design-system';
import {
    signUpPasswordRule,
    mandatoryCheckRules,
} from '../../../lib/validator';
import EmailAndPasswordField from './email-password-block';
import {
    FieldLabel,
    CheckBoxWrapper,
} from './styles';

import Remote, {
    useRemoteState,
} from '../../Forms';



const {
    CheckBox,
    Field,
    ControlledInput,
    useValidationErrors,
    useValidationState,
    useFieldsError,
    useFieldsValue,
} = FormControl;


const CgvConntainer = (userAuthorizeLabelMessage, userCgvLabelMessage) => {
    return (
        <Grid>
            {userAuthorizeLabelMessage
                ?
                <Field>
                    <CheckBoxWrapper>
                        <ControlledInput
                            ControlledElement={CheckBox}
                            name="userAuthorize"
                            validations={[
                                (value) => mandatoryCheckRules(value)
                                    ? ''
                                    : ' '
                            ]}
                        />
                        <FieldLabel as='p'>{userAuthorizeLabelMessage}</FieldLabel>
                    </CheckBoxWrapper>
                </Field>

                : null
            }

            {userCgvLabelMessage
                ? <Field>
                    <CheckBoxWrapper>
                        <ControlledInput
                            ControlledElement={CheckBox}
                            name="userCgv"
                            validations={[
                                (value) => mandatoryCheckRules(value)
                                    ? ''
                                    : ' '
                            ]}
                        />
                        <FieldLabel as='p'>{userCgvLabelMessage}</FieldLabel>
                    </CheckBoxWrapper>
                </Field>
                : null
            }
        </Grid>
    );
};

CgvConntainer.propTypes = {
    userAuthorizeLabelMessage: propTypes.string,
    userCgvLabelMessage: propTypes.node,
};

const Signup = ({
    initialUserEmailValue,
    userEmailLabelMessage,
    userEmailPlaceholderMessage,
    userEmailErrorMessage,
    userPasswordLabelMessage,
    userPasswordPlaceholderMessage,
    userPasswordErrorMessage,
    userAuthorizeLabelMessage,
    userCgvLabelMessage,
    PreForm,
    PostForm,
}) => {
    const dispatch = useRemoteState();

    return (
        <FormControl>
            {PreForm
                ? <PreForm formDispatch={dispatch} />
                : null
            }
            <EmailAndPasswordField
                {...{
                    initialUserEmailValue,
                    userEmailLabelMessage,
                    userEmailPlaceholderMessage,
                    userEmailErrorMessage,
                    userPasswordLabelMessage,
                    userPasswordPlaceholderMessage,
                    userPasswordErrorMessage,
                }}
                passwordSetting={{
                    descriptionMode: true,
                    validations:[
                        (value) => mandatoryCheckRules(value)
                            ? ''
                            : userPasswordErrorMessage,
                        (value) => signUpPasswordRule(value)
                            ? ''
                            : userPasswordErrorMessage

                    ],
                }}
            />
            { CgvConntainer(userAuthorizeLabelMessage,userCgvLabelMessage) }
            {PostForm
                ? <PostForm  formDispatch={dispatch} />
                : null
            }
        </FormControl>
    );
};
Signup.defaultProps = {
    PreForm: null,
    PostForm: null,
};
Signup.propTypes = {
    PreForm: propTypes.any,
    PostForm: propTypes.any,
    initialUserEmailValue: propTypes.string,
    userEmailLabelMessage: propTypes.string,
    userEmailPlaceholderMessage: propTypes.string,
    userEmailErrorMessage: propTypes.string,
    userPasswordLabelMessage: propTypes.string,
    userPasswordPlaceholderMessage: propTypes.string,
    userPasswordErrorMessage: propTypes.string,
    userAuthorizeLabelMessage: propTypes.string,
    userCgvLabelMessage: propTypes.node,
};

/**
 *
 * @param {*} param0
 */
export default function SignupWrapper ({ children, ...props }) {
    return (
        <Remote>
            <Signup {...props}>
                {children}
            </Signup>
        </Remote>
    );
}
SignupWrapper.propTypes = {
    children: propTypes.node,
};
export {
    useValidationErrors,
    useValidationState,
    useFieldsError,
    useFieldsValue
};
