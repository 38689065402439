//@ts-check

import React, {
    useState,
    useEffect,
} from 'react';
import propTypes from 'prop-types';
import {
    useSelector,
} from 'react-redux';
import {
    useTranslation,
} from 'react-i18next';
import {
    useMatomo,
} from '@jonkoops/matomo-tracker-react';
import {
    Cognito,
} from '@testamento/react-components';
import {
    Button,
    Typography,
} from '@testamento/design-system';
import {
    logger,
} from '@testamento/design-system/dist/lib';

import {
    InputContainer,
} from '../Block/Form';
import {
    signIn,
} from '../../store/actions';
import store from '../../store';
import {
    PartnerButton,
} from '../Block/Styling';
import {
    BackLinkContainer,
} from './block';
import ResetPasswordError from '../../pages/ResetPasswordError';

import {
    REGISTER_EFFECT,
    UNREGISTER_EFFECT,
    REGISTER_ERROR,
    UNREGISTER_ERROR,
    REQUEST_PENDING,
    REQUEST_ERROR,
    REQUEST_SUCCESS,
    SpacedFlashbag,
} from '../Forms';
import ResetPasswordForm, {
    useValidationState,
    useFieldsValue,
} from './forms/reset-password';
import {
    handleErrorMessagesFromCognito,
} from '../../utils/errorCognitoHandling';
import AnimatedSpinner, {
    SpinnerWrapper,
} from '../Block/Spinner/index';
import ConfirmationCode from './ConfirmationCode';
const debug = logger('planner:forms:reset-password');

const {
    CognitoInteraction,
    useCognitoHubDispatch,
} = Cognito;

export const CognitoResetPassword = ({ Auth }) => {
    const { t } = useTranslation();
    const { trackPageView, trackEvent } = useMatomo();

    const user = useSelector(state => state.user);
    const config = useSelector(state => state.config);
    const { global = {} } = config;

    React.useEffect(() => {
        trackPageView({
            documentTitle: 'ResetPassword',
        });
    }, [trackPageView]);

    return (
        <ResetPasswordForm
            confirmationCodeLabelMessage={t('auth.reset.confirmation.code.label')}
            confirmationCodePlaceholderMessage={t('auth.reset.confirmation.code.placeholder')}
            confirmationCodeErrorMessage={t('auth.reset.confirmation.code.error')}
            newUserPasswordLabelMessage={t('auth.password')}
            newUserPasswordPlaceholderMessage={t('auth.password')}
            newUserPasswordErrorMessage={t('auth.signup.password_control')}
            PreForm={function PreForm({ formDispatch, children }) {
                const [cognitoErrorCode, setCognitoErrorCode] = useState(null);
                useEffect(() => {
                    formDispatch({ type: REGISTER_ERROR, payload: { effect: setCognitoErrorCode } });
                    return () => {
                        formDispatch({ type: UNREGISTER_ERROR });
                    };
                }, [formDispatch, setCognitoErrorCode]);

                return (
                    <>
                        <InputContainer>
                            <Typography.HeadingXxs>{t('auth.reset.title')}</Typography.HeadingXxs>
                        </InputContainer>
                        <ConfirmationCode
                            {...{
                                setCognitoErrorCode,
                                resendPromise: (userEmail) => Auth.forgotPassword(userEmail),
                                userEmail: user.username,
                                resentTranslationKey: 'auth.confirmation_code.resend_action',
                            }}
                        >
                            {children}
                        </ConfirmationCode>
                        {cognitoErrorCode
                            // eslint-disable-next-line no-undef
                            ? <SpacedFlashbag background='warning' title={t(handleErrorMessagesFromCognito(cognitoErrorCode))}
                            />
                            : null
                        }
                    </>
                );
            }}
            PostForm={function PostForm({ formDispatch }) {
                const [useAuthSpinner, setUseAuthSpinner] = useState(false);
                const [globalError, setGlobalError] = useState(false);
                const hubDispatch = useCognitoHubDispatch();
                const state = useValidationState();
                const values = useFieldsValue();

                useEffect(() => {
                    formDispatch({ type: REGISTER_EFFECT, payload: { effect: setUseAuthSpinner } });
                    return () => {
                        formDispatch({ type: UNREGISTER_EFFECT });
                    };
                }, [formDispatch, setUseAuthSpinner]);

                return (
                    <>
                        {globalError
                            ? <ResetPasswordError colorBackground={global.colorBackground} />
                            : <>
                                <BackLinkContainer
                                    onClick={() => {
                                        store.dispatch(signIn(
                                            hubDispatch
                                        ));}} >
                                    {t('auth.reset.back')}
                                </BackLinkContainer>
                                <PartnerButton color="#FFFFFF" backgroundColor={global && global.colorBackground}>
                                    <AnimatedButton
                                        {...{
                                            useAuthSpinner,
                                            onClick: (event) => {
                                                event.preventDefault();
                                                if (state()) {
                                                    const {
                                                        confirmationCode, newUserPassword,
                                                    } = values();

                                                    trackEvent({ category: 'ResetPassword', action: 'click-reset-password-btn' });
                                                    formDispatch({ type: REQUEST_PENDING });

                                                    Auth.forgotPasswordSubmit(user.username, confirmationCode, newUserPassword)
                                                        .then(
                                                            () => {
                                                                Auth.signIn(user.username, newUserPassword).then(
                                                                    () => {
                                                                        formDispatch({ type: REQUEST_SUCCESS });
                                                                    }
                                                                ).catch(
                                                                    (errorSignIn) => {
                                                                        const {
                                                                            code,
                                                                        } = errorSignIn;

                                                                        formDispatch({ type: REQUEST_ERROR, payload: { code } });
                                                                    }
                                                                );
                                                            },
                                                            resetPasswordError => {
                                                                const {
                                                                    code,
                                                                } = resetPasswordError;

                                                                formDispatch({ type: REQUEST_ERROR, payload: { code } });
                                                            }
                                                        )
                                                        .catch(globalAuthError => {
                                                            debug({ globalAuthError });
                                                            setGlobalError(true);
                                                        });
                                                }
                                            },
                                        }}
                                    />
                                </PartnerButton>
                            </>
                        }
                    </>
                );
            }}
        />
    );
};
CognitoResetPassword.propTypes = {
    Auth: propTypes.any,
};

export const ResetPassword = () => (
    <CognitoInteraction>
        {(Auth) => (
            <CognitoResetPassword {...{
                Auth,
            }} />
        )}
    </CognitoInteraction>
);


export default ResetPassword;
/**
 * @param {object} props
 * @param {boolean} props.useAuthSpinner
 * @param {function} props.onClick
 * @returns ReactElement
 */
function AnimatedButton({ useAuthSpinner, onClick }) {
    const { t } = useTranslation();
    return (
        <Button
            disabled={!!useAuthSpinner}
            onClick={onClick}
        >
            {useAuthSpinner
                ? (
                    <SpinnerWrapper>
                        <AnimatedSpinner />
                        {t('auth.reset.confirmation.action')}
                    </SpinnerWrapper>
                )
                : t('auth.reset.confirmation.action')
            }
        </Button>
    );
}
AnimatedButton.propTypes = {
    useAuthSpinner: propTypes.bool,
    onClick: propTypes.func,
};
