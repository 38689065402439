//@ts-check

import React from 'react';
import propTypes from 'prop-types';
import {
    useMatomo,
} from '@jonkoops/matomo-tracker-react';

import {
    PartnerButton,
} from '../components/Block/Styling';

import {
    ActionsWrapper,
    FixedPage,
} from './styles';
import {
    Typography,
    Button,
} from '@testamento/design-system';


/**
 *
 * @param {*} props
 * @returns
 */
export default function DefaultError({
    title,
    message,
    action,
    colorBackground,
}) {
    const { trackEvent } = useMatomo();
    trackEvent({ category: 'DefaultErrorPage', action: 'default-error' });

    return (
        <FixedPage pageType='error' >
            <Typography.HeadingMd aria-label='page-title' >
                {title}
            </Typography.HeadingMd>
            <Typography.BodyL>
                {message}
            </Typography.BodyL>
            {action && (
                <ActionsWrapper>
                    <PartnerButton color="#FFFFFF" backgroundColor={colorBackground}>
                        <Button onClick={() => window.location.replace('/')} >
                            {action}
                        </Button>
                    </PartnerButton>
                </ActionsWrapper>
            )}
        </FixedPage>
    );
}

DefaultError.propTypes = {
    action: propTypes.string,
    title: propTypes.string,
    message: propTypes.string,
    colorBackground: propTypes.string,
};
