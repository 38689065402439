import React, {
    createContext,
} from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import{
    FormControl,
} from '@testamento/design-system';
import {
    logger,
} from '@testamento/design-system/dist/lib';

const ErrorContext = createContext();
const debug = logger('error-logger');
const {
    useFieldErrorNotifier,
} = FormControl;

/**
 *
 * @param {Object} props
 * @param {React.ReactNode} props.children
 * @param {String} props.name
 * @returns {React.ReactNode}
 */
export default function ErrorRenderer ({ children, name }) {
    const errors = useFieldErrorNotifier(name);
    debug('%cErrorRenderer', 'color:red;font-weight:bold;', { [name]: errors });

    return (errors && errors.length > 0
        ? (
            <ErrorContext.Provider value={errors}>
                {children}
            </ErrorContext.Provider>
        )
        : null
    );
}

ErrorRenderer.propTypes = {
    children: propTypes.node,
    name: propTypes.string,
};

ErrorRenderer.Label = styled.span`
    color: red;
`;
ErrorRenderer.Consumer = ErrorContext.Consumer;

const Wrapper =  ({ children, name }) => {
    const foundErrors = useFieldErrorNotifier(name);
    return (
        <ErrorContext.Provider value={foundErrors}>
            <ErrorContext.Consumer>

                {(errors) => (errors && errors.length > 0
                    ? (
                        <ErrorRenderer.Label>
                            {children}
                        </ErrorRenderer.Label>
                    )
                    : children
                )}

            </ErrorContext.Consumer>
        </ErrorContext.Provider>
    );
};
Wrapper.propTypes = {
    children: propTypes.node,
    name: propTypes.string,
};
ErrorRenderer.Wrapper = Wrapper;
