import React from 'react';
import propTypes from 'prop-types';

/**
 *
 * @param {Object} props
 * @param {string} fillColor
 * @returns
 */
export default function LogOut ({ fillColor }) {
    return <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0)">
            <path d="M11.96 2.99H2.99C2.43 2.99 1.99 3.44 1.99 3.99V21.95C1.99 22.51 2.43 22.95 2.99 22.95H11.96C12.51 22.95 12.95 23.40 12.95 23.95C12.95 24.50 12.51 24.95 11.96 24.95H2.99C1.34 24.95 0 23.61 0 21.95V3.99C0 2.34 1.34 1.00 2.99 1.00H11.96C12.51 1.00 12.95 1.44 12.95 1.99C12.95 2.55 12.51 2.99 11.96 2.99Z" fill={fillColor}/>
            <path d="M23.70 13.68L17.64 19.67C17.25 20.06 16.62 20.05 16.23 19.66C15.84 19.27 15.85 18.64 16.24 18.25L20.57 13.97H8.97C8.41 13.97 7.97 13.52 7.97 12.97C7.97 12.42 8.41 11.97 8.97 11.97H20.57L16.24 7.69C15.85 7.31 15.84 6.68 16.23 6.28C16.42 6.09 16.68 5.99 16.94 5.99C17.19 5.99 17.44 6.08 17.64 6.27L23.70 12.26C23.89 12.45 24 12.70 24 12.97C24 13.24 23.89 13.49 23.70 13.68Z" fill={fillColor}/>
        </g>
        <defs>
            <clipPath id="clip0">
                <rect width="24" height="24.03" fill="white" transform="matrix(1 0 0 -1 0 24.99)"/>
            </clipPath>
        </defs>
    </svg>;
}

LogOut.propTypes = {
    fillColor: propTypes.string,
};

LogOut.defaultProps = {
    fillColor: 'black',
};
