//@ts-check

import React, {
    useState,
} from 'react';
import {
    useSelector,
} from 'react-redux';
import propTypes from 'prop-types';
import {
    Cognito,
} from '@testamento/react-components';
import {
    logger,
} from '@testamento/design-system/dist/lib';

import trackActivity from '../utils/trackActivity';
import {
    getUserAuthorization,
} from '../utils/user';
import LoaderReact from '../components/LoaderReact.jsx';
import SignOutError from '../pages/signOutError';
import {
    useNavigate,
} from 'react-router-dom';

const {
    CognitoInteraction,
    useCognitoValuesWatcher,
} = Cognito;
const debug = logger('planner:contexts:logout');

const suspenseLogout = (user, logoutPromise, setSignOutError) => {
    const result = {
        logout: 'pending',
    };

    const logoutProcess = (user !== 'visitor'
        ? trackActivity(
            getUserAuthorization(user),
            'logout'
        ).finally(
            () => logoutPromise({ global: true }).then(() => {
                result.logout = 'done';
                localStorage.removeItem(
                    'secureSession'
                );
            }).catch((error) => {
                result.logout = error;
                debug(error);
                setSignOutError(true);
            })
        )
        : null
    );

    return () => {
        if (user === 'visitor') return 'none';
        if (result.logout === 'pending') throw logoutProcess;
        if (result.logout === 'done') return 'done';
        throw result.logout;
    };
};

/**
 *
 * @param {*} props
 * @returns
 */
function  Redirection ({ Auth }) {
    const [signOutError, setSignOutError] = useState(false);
    const config = useSelector(state => state.config);
    const navigate = useNavigate();

    const { global = {} } = config;
    const {
        user,
    } = useCognitoValuesWatcher(['user'], 'userDispatch');

    const logoutResult = suspenseLogout(
        user,
        Auth.signOut,
        setSignOutError
    )();

    React.useEffect(() => {
        navigate('/');
    });

    debug({ logoutResult });
    if (signOutError) {
        return (
            <SignOutError colorBackground={global.colorBackground} />
        );
    }
    return null;
}
Redirection.propTypes = {
    Auth: propTypes.shape({
        signOut: propTypes.func,
    }),
};
/**
 *
 * @returns ReactElement
 */
export default function LogoutRedirection() {
    return (
        <CognitoInteraction>
            {(Auth) =>
                <React.Suspense fallback={<LoaderReact context="logout in progress" />}>
                    <Redirection {...{ Auth }} />
                </React.Suspense>
            }
        </CognitoInteraction>
    );
}
