import React, {
    createContext,
    useContext,
} from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import {
    Flashbag,
} from '@testamento/design-system';
const RemoteAction = createContext();

export const REGISTER_EFFECT = 'REGISTER_EFFECT';
export const UNREGISTER_EFFECT = 'UNREGISTER_EFFECT';
export const REGISTER_ERROR = 'REGISTER_ERROR';
export const UNREGISTER_ERROR = 'UNREGISTER_ERROR';
export const REQUEST_PENDING = 'REQUEST_PENDING';
export const REQUEST_SUCCESS = 'REQUEST_SUCCESS';
export const REQUEST_ERROR = 'REQUEST_ERROR';

const remoteStateReducer = (state, action) => {
    if (action && action.type) {
        switch (action.type) {
            case REGISTER_EFFECT: {
                return {
                    ...state,
                    spinnerEffect: action.payload.effect,
                };
            }
            case UNREGISTER_EFFECT: {
                return {
                    ...state,
                    spinnerEffect: undefined,
                };
            }
            case REGISTER_ERROR: {
                return {
                    ...state,
                    remoteError: action.payload.effect,
                };
            }
            case UNREGISTER_ERROR: {
                return {
                    ...state,
                    remoteError: undefined,
                };
            }
            case REQUEST_PENDING: {
                if(state.spinnerEffect && state.remoteError) {
                    state.spinnerEffect(true);
                    state.remoteError(null);

                }
                return {
                    ...state,
                };
            }
            case REQUEST_SUCCESS: {
                if(state.spinnerEffect && state.remoteError) {
                    state.spinnerEffect(false);
                    state.remoteError(null);
                }
                return {
                    ...state,
                };
            }
            case REQUEST_ERROR: {
                if(state.spinnerEffect && state.remoteError) {
                    state.spinnerEffect(false);
                    state.remoteError(action.payload.code);
                }
                return {
                    ...state,
                };
            }
            default: {
                throw new Error(`given action.type (${action.type}) is not handled`);
            }
        }
    }
    throw new Error(`given action is not handled "${JSON.stringify(action, null, 2)}"`);
};
/**
 *
 */
export default function Remote({ children }) {
    let state = {};
    const dispatch = (action) => {
        state = remoteStateReducer(state, action);
    };

    return (
        <RemoteAction.Provider value={dispatch}>
            {children}
        </RemoteAction.Provider>
    );
}
Remote.propTypes = {
    children: propTypes.node,
};

/**
 *
 */
export function useRemoteState () {
    const context = useContext(RemoteAction);
    if (context === undefined) {
        throw new Error('"useRemoteState" must be used in "Remote" context');
    }
    return context;
}
export const SpacedFlashbag = styled(Flashbag)`
margin-bottom: 2rem;
`;
