import React from 'react';
import propTypes from 'prop-types';
import {
    Spinner, FlexInlineContainer, 
} from './styles/Spinner';


/**
 *
 * @param {*} param0
 */
export default function AnimatedSpinner({ alt, ...restProps }) {
    return (
        <Spinner viewBox="0 0 50 50" {...restProps} >
            {alt
                ? <title>{alt}</title>
                : null
            }
            <circle cx="25" cy="25" r="20" fill="none" strokeWidth="5" ></circle>
        </Spinner>
    );
}

AnimatedSpinner.propTypes = {
    alt: propTypes.node,
    restProps: propTypes.any,
};


export const SpinnerWrapper = ({ children }) => {
    return <FlexInlineContainer>{children}</FlexInlineContainer>;
};

SpinnerWrapper.propTypes = {
    children: propTypes.any,
};
