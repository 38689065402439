
import React from 'react';
import propTypes from 'prop-types';
import {
    FormControl,
} from '@testamento/design-system';
import {
    mandatoryCheckRules,
} from '../../../lib/validator';
import EmailAndPasswordField from './email-password-block';

import Remote, {
    useRemoteState,
} from '../../Forms';

const {
    useValidationErrors,
    useValidationState,
    useFieldsError,
    useFieldsValue,
} = FormControl;

const Signin = ({
    initialUserEmailValue,
    userEmailLabelMessage,
    userEmailPlaceholderMessage,
    userEmailErrorMessage,
    userPasswordLabelMessage,
    userPasswordPlaceholderMessage,
    userPasswordErrorMessage,
    userPasswordResetCallToAction,
    PreForm,
    PostForm,
}) => {
    const dispatch = useRemoteState();

    return (
        <FormControl>
            {PreForm
                ? <PreForm formDispatch={dispatch} />
                : null
            }
            <EmailAndPasswordField
                {...{
                    initialUserEmailValue,
                    userEmailLabelMessage,
                    userEmailPlaceholderMessage,
                    userEmailErrorMessage,
                    userPasswordLabelMessage,
                    userPasswordPlaceholderMessage,
                    userPasswordErrorMessage,
                    userPasswordResetCallToAction,
                }}
                passwordSetting={{
                    descriptionMode: false,
                    validations:[
                        (value) => (mandatoryCheckRules(value)
                            ? ''
                            : userPasswordErrorMessage
                        )
                    ],
                }}
            />
            {PostForm
                ? <PostForm  formDispatch={dispatch} />
                : null
            }
        </FormControl>
    );
};
Signin.defaultProps = {
    PreForm: null,
    PostForm: null,
};
Signin.propTypes = {
    PreForm: propTypes.any,
    PostForm: propTypes.any,
    initialUserEmailValue: propTypes.string,
    userEmailLabelMessage: propTypes.string,
    userEmailPlaceholderMessage: propTypes.string,
    userEmailErrorMessage: propTypes.string,
    userPasswordLabelMessage: propTypes.string,
    userPasswordPlaceholderMessage: propTypes.string,
    userPasswordErrorMessage: propTypes.string,
    userPasswordResetCallToAction: propTypes.node,
};

/**
 *
 * @param {*} param0
 */
export default function SigninWrapper ({ children, ...props }) {
    return (
        <Remote>
            <Signin {...props}>
                {children}
            </Signin>
        </Remote>
    );
}
SigninWrapper.propTypes = {
    children: propTypes.node,
};

export {
    useValidationErrors,
    useValidationState,
    useFieldsError,
    useFieldsValue
};
