//@ts-check

import React from 'react';
import propTypes from 'prop-types';
import {
    useTranslation,
} from 'react-i18next';
import {
    useMatomo,
} from '@jonkoops/matomo-tracker-react';

import DefaultError from './DefaultError';

/**
 *
 * @param {*} props
 * @returns
 */
export default function SessionTokenError({ colorBackground }) {
    const { t } = useTranslation((['errors']));
    const { trackEvent } = useMatomo();
    trackEvent({ category: 'SessionTokenError', action: 'session-token-error' });

    return (
        <DefaultError
            title={t('session_token_error_page.title')}
            message={t('session_token_error_page.content')}
            action={t('session_token_error_page.cta')}
            colorBackground={colorBackground}
        />
    );
}

SessionTokenError.propTypes = {
    colorBackground: propTypes.string,
};
