//@ts-check

import React, {
    useState,
    useEffect,
} from 'react';
import propTypes from 'prop-types';
import {
    useTranslation,
    Trans,
} from 'react-i18next';
import {
    Button,
    Typography,
} from '@testamento/design-system';
import {
    useMatomo,
} from '@jonkoops/matomo-tracker-react';
import {
    Cognito,
} from '@testamento/react-components';


import {
    REGISTER_EFFECT,
    UNREGISTER_EFFECT,
    REGISTER_ERROR,
    UNREGISTER_ERROR,
    REQUEST_PENDING,
    REQUEST_SUCCESS,
    REQUEST_ERROR,
    SpacedFlashbag,
} from '../Forms';
import Signup, {
    useValidationState, useFieldsValue,
} from './forms/signup';
import AnimatedSpinner, {
    SpinnerWrapper,
} from '../Block/Spinner/index';
import {
    PartnerButton,
} from '../Block/Styling';
import {
    prepareSignupCodeValidation,
    reSign,
    confirmSignUp,
} from '../../store/actions';
import store from '../../store';
import {
    handleErrorMessagesFromCognito,
} from '../../utils/errorCognitoHandling';

const {
    CognitoInteraction,
    useCognitoHubDispatch,
} = Cognito;

const UsernameExistsException = 'UsernameExistsException';

const CognitoSignUp = ({ colorBackground, Auth }) => {
    const { t } = useTranslation();
    const { trackPageView, trackEvent } = useMatomo();
    const hubDispatch = useCognitoHubDispatch();

    useEffect(() => {
        trackPageView({
            documentTitle: 'Signup',
        });
    }, [trackPageView]);

    return (
        <Signup
            initialUserEmailValue=""
            userEmailLabelMessage={t('auth.email')}
            userEmailPlaceholderMessage={t('auth.email')}
            userEmailErrorMessage={t('auth.signin.username_hint')}
            userPasswordLabelMessage={t('auth.password')}
            userPasswordPlaceholderMessage={t('auth.password')}
            userPasswordErrorMessage={t('auth.signup.password_control')}
            userAuthorizeLabelMessage={t('auth.signup.authorize')}
            userCgvLabelMessage={(function cgvLabel$TranslateTemplate(){
                return t('auth.signup.cgv_format')
                    ? (
                        <Trans
                            i18nKey="auth.signup.cgv_format"
                        >
                            <a href={t('auth.signup.cgv_url')} target="_blank" rel="noopener noreferrer">
                                {t('signup.cgv_description')}
                            </a>
                        </Trans>
                    )
                    : null
                ;
            })()}
            PreForm={function PreForm({ formDispatch }) {
                const [cognitoErrorCode, $cognitoErrorCode] = useState(null);

                useEffect(() => {
                    formDispatch({ type: REGISTER_ERROR, payload:{ effect: $cognitoErrorCode } });
                    return () => {
                        formDispatch({ type: UNREGISTER_ERROR });
                    };
                },[formDispatch, $cognitoErrorCode]);

                return (
                    <>
                        {cognitoErrorCode
                            ? <SpacedFlashbag background='warning' title={t(handleErrorMessagesFromCognito(cognitoErrorCode))} />
                            : null
                        }
                        <Typography.HeadingXs style={{ paddingBottom: '2em' }}>{t('auth.signup.create_account')}</Typography.HeadingXs>
                    </>
                );
            }}

            PostForm={function PostForm({ formDispatch }) {
                const state = useValidationState();
                const values =  useFieldsValue();
                const [useAuthSpinner, setUseAuthSpinner] = useState(false);

                useEffect(() => {
                    formDispatch({ type: REGISTER_EFFECT, payload:{ effect: setUseAuthSpinner } });
                    return () => {
                        formDispatch({ type: UNREGISTER_EFFECT });
                    };
                },[formDispatch, setUseAuthSpinner] );
                return (
                    <PartnerButton color="#FFFFFF" backgroundColor={colorBackground}>
                        <Button
                            disabled={ useAuthSpinner ? true : false}
                            onClick={(event)=> {
                                event.preventDefault();
                                if(state()) {
                                    const {
                                        userMail,
                                        userPassword,
                                    } = values();

                                    trackEvent({ category: 'Signup', action: 'click-signup-btn' });
                                    formDispatch({ type: REQUEST_PENDING });
                                    Auth.signUp({
                                        username: userMail,
                                        password: userPassword,
                                    })
                                        .then(() => {
                                            formDispatch({ type: REQUEST_SUCCESS });
                                            store.dispatch(prepareSignupCodeValidation(userMail, userPassword));
                                            store.dispatch(confirmSignUp(
                                                hubDispatch
                                            ));
                                        })
                                        .catch((errorSignUp) => {
                                            if (errorSignUp.code === UsernameExistsException) {
                                                Auth.resendSignUp(userMail).then(
                                                    () => {
                                                        return store.dispatch(reSign(
                                                            { username: userMail }, hubDispatch
                                                        ));
                                                    },
                                                    (resendSignUpError) => {
                                                        const {
                                                            code,
                                                        } = resendSignUpError;

                                                        formDispatch({ type: REQUEST_ERROR, payload:{ code } });
                                                    }
                                                ).catch((globalError) => {
                                                    const {
                                                        code,
                                                    } = globalError;

                                                    formDispatch({ type: REQUEST_ERROR, payload:{ code } });
                                                });
                                            } else {
                                                const {
                                                    code,
                                                } = errorSignUp;

                                                formDispatch({ type: REQUEST_ERROR, payload:{ code } });
                                            }
                                        });
                                }
                            }}
                        >
                            {useAuthSpinner
                                ? <SpinnerWrapper>
                                    <AnimatedSpinner />
                                    {t('auth.signup.subscribe')}
                                </SpinnerWrapper>
                                : t('auth.signup.subscribe')
                            }
                        </Button>
                    </PartnerButton>
                );
            }}
        />
    );
};
CognitoSignUp.propTypes = {
    colorBackground: propTypes.string.isRequired,
    Auth: propTypes.any,
};

export const SignUp = ({ colorBackground }) => (
    <CognitoInteraction>
        {(Auth) => (
            <CognitoSignUp {...{
                colorBackground,
                Auth,
            }} />
        )}
    </CognitoInteraction>
);

SignUp.propTypes = {
    colorBackground: propTypes.string.isRequired,
};

export default SignUp;
