import React from 'react';
import propTypes from 'prop-types';


/**
 * 
 * @param {*} param0 
 * @returns 
 */
export default function Trash({ color }){
    return (
        <svg width="24" height="24" viewBox="0 0 48 48" fill="none" >
            <path d="M12 16V42.5C12 43.3284 12.6716 44 13.5 44H34.5C35.3284 44 36 43.3284 36 42.5V16H12Z" fill={color} />
            <path opacity="0.6" fillRule="evenodd" clipRule="evenodd" d="M28 9V7C28 6.44772 27.5523 6 27 6H21C20.4477 6 20 6.44772 20 7V9H10.5C10.2239 9 10 9.22386 10 9.5V11.5C10 11.7761 10.2239 12 10.5 12H37.5C37.7761 12 38 11.7761 38 11.5V9.5C38 9.22386 37.7761 9 37.5 9H28Z" fill={color} />
        </svg>
    );
}

Trash.propTypes = {
    color: propTypes.string,
};
