import styled, {
    keyframes, 
} from 'styled-components';

const Rotate = keyframes`
    100% {
        transform: rotate(360deg);
     }
`;

const Dash = keyframes`
    0% {
        stroke-dasharray: 1, 150;
        stroke-dashoffset: 0;
    }
    50% {
        stroke-dasharray: 90, 150;
        stroke-dashoffset: -35;
    }
    100% {
        stroke-dasharray: 90, 150;
        stroke-dashoffset: -124;
    }
`;

export const Spinner = styled.svg`
    animation: ${Rotate} 2s linear infinite;
    width: 24px;
    height: 24px;

    circle {
        stroke: rgba(255, 255, 255, 0.6);
        stroke-linecap: round;
        animation: ${Dash} 1.3s ease-in-out infinite;
    }
`;

export const FlexInlineContainer = styled.span`
    display: flex;
    align-items: center;

    svg {
        margin-right: 0.9rem;
    }
`;
