//@ts-check

import React from 'react';
import {
    decode,
} from 'he';
import {
    useTranslation,
} from 'react-i18next';
import propTypes from 'prop-types';
import styled from 'styled-components';
import {
    Page,
    Typography,
    Button,
} from '@testamento/design-system';
import {
    FragmentStringToJSX,
} from '@testamento/design-system/dist/lib';
import {
    useMatomo,
} from '@jonkoops/matomo-tracker-react';

import {
    ActionsWrapper,
} from '../pages/styles';
import {
    PartnerButton,
} from '../components/Block/Styling';

const ParameterHighlighting = styled.strong`
     font-family: monospace;
 `;

const ParametersList = styled.ul`
     font-size: 1.6rem;
     line-height: 1.2em;
     font-weight: 400;
     border-radius: 2px;
     background-color: lightyellow;
     list-style-type: '- ';
     padding:  1.5rem;
 `;

const Parameter = ({ children }) => (
    <li>{children}</li>
);
Parameter.propTypes = {
    children: propTypes.node,
};

/**
 *
 */
export default function CognitoOAuthError({ oAuthError, colorBackground }) {
    const { t } = useTranslation((['errors']));
    const { trackEvent } = useMatomo();
    trackEvent({
        category: 'OAuthErrorPage',
        action: 'oauth-error',
        value: oAuthError,
    });
    return (
        <Page
            title={t(`oauth_error_page.${oAuthError.code}.title`)}
            pageType='event'
        >
            <FragmentStringToJSX
                content={decode(t(`oauth_error_page.${oAuthError.code}.description`, {
                    error_description: oAuthError.error_description,
                    state: oAuthError.state,
                }))}
                mappings={{
                    '0': Typography.BodyL,
                    '2': ParametersList,
                    '3': Parameter,
                    '4': ParameterHighlighting,
                }}
            />
            <ActionsWrapper>
                <PartnerButton color="#FFFFFF" backgroundColor={colorBackground}>
                    <Button onClick={() => window.location.reload()} >
                        {t(`oauth_error_page.${oAuthError.code}.cta`)}
                    </Button>
                </PartnerButton>
            </ActionsWrapper>
        </Page>
    );
}

CognitoOAuthError.propTypes = {
    oAuthError: propTypes.shape({
        code: propTypes.string,
        error_description: propTypes.string,
        state: propTypes.string,
    }),
    colorBackground: propTypes.string,
};
