import React, {
    Fragment,
    createContext,
    useState,
    useLayoutEffect,
} from 'react';
import {
    useSelector,
} from 'react-redux';
import propTypes from 'prop-types';
import styled, {
    css,
} from 'styled-components';
import {
    useTranslation,
} from 'react-i18next';
import {
    FormControl,
    Typography,
    Button,
    Flashbag,
} from '@testamento/design-system';
import {
    Cognito,
} from '@testamento/react-components';
import {
    logger,
} from '@testamento/design-system/dist/lib';
import {
    SECURE_ACCOUNT_PARAMETERS_PATH,
} from '../constants';
import {
    RedirectionSetter,
} from '../components/ConnectedPage';

import ErrorRenderer from '../components/Forms/ErrorRenderer';
import AnimatedSpinner, {
    SpinnerWrapper,
} from '../components/Block/Spinner/index';
import {
    PartnerButton,
} from '../components/Block/Styling';
import Padlock from '../icons/Padlock';
import {
    signUpPasswordRule,
    mandatoryCheckRules,
} from '../lib/validator';
import {
    FormCell,
    FieldWrapper,
    FieldLabel,
} from '../components/Auth/forms/styles';
import trackActivity from '../utils/trackActivity';
import {
    getUserAuthorization,
} from '../utils/user';

const debug = logger('planner:contexts:change-password');
const {
    Field,
    ControlledInput,
    useMultipleFieldsWatcher,
    TextInput,
} = FormControl;
const {
    CognitoInteraction,
    useCognitoValuesWatcher,
} = Cognito;



const SpinnerConsumer = createContext(null);
const SpinnerRegister = createContext(null);
const CognitoError = createContext(null);
const CognitoErrorRegister = createContext(null);
const SpinnerPortal = ({ children }) => {
    let spinnerUpdater;
    let cognitoErrorUpdater;
    return (
        <CognitoError.Provider value={() => cognitoErrorUpdater}>
            <CognitoErrorRegister.Provider value={(method) => {
                cognitoErrorUpdater = method;
            }}>

                <SpinnerConsumer.Provider value={() => spinnerUpdater}>
                    <SpinnerRegister.Provider
                        value={(method) => {
                            spinnerUpdater = method;
                        }}
                    >
                        {children}
                    </SpinnerRegister.Provider>
                </SpinnerConsumer.Provider>
            </CognitoErrorRegister.Provider>
        </CognitoError.Provider>
    );
};

const Fieldset = styled.fieldset`
    text-align: left;
    font-size: 1.2rem;
    padding: 1.7rem 3.6rem;
    margin: 0;
    border: 1px solid ${({ theme }) => theme.colors.borders};
    border-radius: 4px;
    background-color: #F4F7F9;
`;

SpinnerPortal.propTypes = {
    children: propTypes.node,
};

const SaveNewPasswordButton = () => {
    const { t } = useTranslation();
    const [
        useAuthSpinner,
        setUseAuthSpinner
    ] = useState(false);

    return <SpinnerRegister.Consumer>
        {(register) => {
            register(setUseAuthSpinner);

            return <SaveButton name="save">
                {useAuthSpinner
                    ? <SpinnerWrapper>
                        <AnimatedSpinner />
                        {t('reset-password.form.action.save')}
                    </SpinnerWrapper>
                    : t('reset-password.form.action.save')
                }
            </SaveButton>;
        }}
    </SpinnerRegister.Consumer>;
};

const adaptTranslation = (base, append) => {
    const appendRules = append.split('/');
    return (appendRules.length === 2
        ? appendRules[1]
        : `${base}.${append}`
    );
};

const usePasswordFieldsWatcher = (dependencies) => {
    const fields = useMultipleFieldsWatcher(dependencies);

    return dependencies.reduce(
        (fieldsState, fieldName, i) => Object.assign(fieldsState, { [fieldName]: fields[i] }),
        {}
    );
};
const TextLabel = styled(FieldLabel)`
    font-size: 1.6rem;
`;
const PasswordField = ({ validation, fieldName, siblingDependencies }) => {
    const { t } = useTranslation();
    const siblingsStates = usePasswordFieldsWatcher(siblingDependencies);


    return <Field Element={FieldWrapper}>
        <TextLabel as="p">
            <ErrorRenderer.Wrapper name={fieldName}>
                {t(`reset-password.form.${fieldName}.label`)}
            </ErrorRenderer.Wrapper>
        </TextLabel>
        <FormCell>
            <ControlledInput
                ControlledElement={TextInput.Password}
                name={fieldName}
                autoWidth={'true'}
                placeholder={t(`reset-password.form.${fieldName}.label`)}
                validations={[validation(siblingsStates)]} />
            <ErrorRenderer name={fieldName}>
                <Typography.Body>
                    <ErrorRenderer.Label>
                        <ErrorRenderer.Consumer>
                            {(error) => t(
                                adaptTranslation(
                                    `reset-password.form.${fieldName}.errors`,
                                    error[0]
                                )
                            )}
                        </ErrorRenderer.Consumer>
                    </ErrorRenderer.Label>
                </Typography.Body>
            </ErrorRenderer>
        </FormCell>
    </Field>;
};
PasswordField.defaultProps = {
    siblingDependencies: [],
};
PasswordField.propTypes = {
    validation: propTypes.func,
    fieldName: propTypes.string,
    siblingDependencies: propTypes.arrayOf(propTypes.string),
};

const ButtonContainer = styled.section`
    display : flex;
    justify-content: end;
    align-items: end;
`;


const CancelButton = styled(Button)`
    border: 2px solid ${props => props.theme.colors.primary};
    color : ${props => props.theme.colors.primary};
    font-size: 1.6rem;
    padding: .5rem 1rem;
    min-width: 12rem;
    margin: .5em .5em .5em 0;
    background-color : ${props => props.theme.colors.white};
    &:hover,&:focus{
        background-color : ${props => props.theme.colors.white};
    }
`;

const SaveButton = styled(Button)`
    border: 2px solid ${props => props.theme.colors.primary};
    color : ${props => props.theme.colors.white};
    font-size: 1.6rem;
    padding: .5rem 1rem;
    min-width: 12rem;
    margin: .5em 0 .5em .5em;
    background-color : ${props => props.theme.colors.primary};
    &:hover, &:focus{
        background-color : ${props => props.theme.colors.primary};
    }
`;

const Fields = () => {
    const { t } = useTranslation();
    return <Fieldset>
        <PasswordField
            fieldName="current-password"
            validation={() => (value) => mandatoryCheckRules(value)
                ? ''
                : 'missing'} />
        <PasswordField
            fieldName="new-password"
            validation={() => (value) => {
                debug({
                    signUpPasswordRule: signUpPasswordRule.toString(),
                    value,
                    validation: signUpPasswordRule(value),
                });
                return (signUpPasswordRule(value)
                    ? ''
                    : '/auth.signup.password_control'
                );
            }} />
        <PasswordField
            fieldName="password-confirmation"
            validation={(dependsOn) => (value) => dependsOn['new-password'] === value
                ? ''
                : 'mismatch'}
            siblingDependencies={['new-password']} />

        <ButtonContainer>
            <CancelButton name="cancel">
                {t('reset-password.form.action.cancel')}
            </CancelButton>
            <SaveNewPasswordButton />
        </ButtonContainer>
    </Fieldset>;
};

const PageTitle = styled(Typography.HeadingXl)`
    font-size: 2.4rem;
    display: flex;
    justify-content: center;
    letter-spacing: 0;
    align-items: center;
    margin-bottom: 6rem;
    svg {
        margin-right: 1.2rem;
    }
`;
const FormDescription = styled(Typography.Body)`
    margin-bottom: 1.2rem;
`;


const paddedBlock = css`
    margin: 0 0 2rem;
`;

const ErrorFlashBag = styled(Flashbag)`${paddedBlock}`;

const ChangePasswordError = ({ isLocked }) => {
    const [
        error,
        setError
    ] = useState('');
    const { t } = useTranslation();

    return <CognitoErrorRegister.Consumer>
        {(register) => {
            register(setError);
            return error
                ? <ErrorFlashBag background="danger" title={t(error)} {...{ isLocked }} />
                : null;
        }}
    </CognitoErrorRegister.Consumer>;
};

ChangePasswordError.propTypes = {
    isLocked: propTypes.bool,
};

const Layout = styled.section`
    max-width: 1024px;
    margin: 0 auto;
`;
const FederationLayout = styled(Layout)`
    height: 75vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

export const FederateLoginLayout = () => {
    const { t } = useTranslation();
    const config = useSelector(state => state.config);
    const { global = {} } = config;

    return (
        <FederationLayout>
            <Typography.HeadingS>{t('reset-password.federation.title')}</Typography.HeadingS>
            <br />
            <Typography.Body>{t('reset-password.federation.content')}</Typography.Body>
            <br />
            <RedirectionSetter>
                {(setRedirectionPath) => (
                    <PartnerButton
                        modifier='primary'
                        color='white'
                        backgroundColor={global.colorBackground}>
                        <Button onClick={(event) => {
                            event.preventDefault();
                            setRedirectionPath(
                                SECURE_ACCOUNT_PARAMETERS_PATH()
                            );
                        }} >{t('reset-password.federation.action')}</Button>
                    </PartnerButton>
                )}
            </RedirectionSetter>
        </FederationLayout>
    );
};

/**
 *
 * @returns ReactElement
 */
export default function ResetPassword() {
    const { t } = useTranslation();
    const [isfederate, setIsFederate] = useState(false);
    const { user } = useCognitoValuesWatcher(['user'], 'userDispatch');

    useLayoutEffect(() => {
        if (user.attributes.identities) {
            setIsFederate(true);
        }
    }, []);

    return (
        <Fragment>
            {isfederate
                ? <FederateLoginLayout />
                : <SpinnerPortal>
                    <Layout>
                        <PageTitle>
                            <Padlock />
                            {t('reset-password.title')}
                        </PageTitle>
                        <FormDescription>
                            {t('reset-password.description')}
                        </FormDescription>
                        <ChangePasswordError isLocked={true} />
                        <RedirectionSetter>
                            {(setRedirectionPath) => (
                                <SpinnerConsumer.Consumer>
                                    {(spinnerUpdater) => (
                                        <CognitoError.Consumer>
                                            {(errorUpdater) => (

                                                <CognitoInteraction>
                                                    {(Auth) => <FormControl
                                                        onSubmit={
                                                            (
                                                                event,
                                                                {
                                                                    // remoteDispatch,
                                                                    isValid,
                                                                    values,
                                                                    errors,
                                                                }
                                                            ) => {
                                                                event.preventDefault();
                                                                switch (event.nativeEvent.submitter.getAttribute('name')) {
                                                                    case 'cancel': {
                                                                        setRedirectionPath(
                                                                            SECURE_ACCOUNT_PARAMETERS_PATH()
                                                                        );
                                                                        break;
                                                                    }
                                                                    case 'save': {
                                                                        if (
                                                                            isValid()
                                                                        ) {
                                                                            spinnerUpdater()(true);
                                                                            const {
                                                                                'current-password': oldPassword,
                                                                                'new-password': newPassword,
                                                                            } = values();

                                                                            Auth.changePassword(user, oldPassword, newPassword).then(
                                                                                () => {
                                                                                    trackActivity(
                                                                                        getUserAuthorization(user),
                                                                                        'change password'
                                                                                    ).finally(
                                                                                        () => {
                                                                                            setRedirectionPath(
                                                                                                SECURE_ACCOUNT_PARAMETERS_PATH()
                                                                                            );
                                                                                        }
                                                                                    );
                                                                                }
                                                                            ).catch((cognitoError) => {
                                                                                spinnerUpdater()(false);
                                                                                errorUpdater()(cognitoError);
                                                                            });
                                                                        } else {
                                                                            debug(errors());
                                                                        }
                                                                        break;
                                                                    }
                                                                    default: {
                                                                        debug(`"${event.nativeEvent.submitter.getAttribute('name')}" is an unknown button name`);
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    >
                                                        <Fields />
                                                    </FormControl>}
                                                </CognitoInteraction>
                                            )}
                                        </CognitoError.Consumer>
                                    )}
                                </SpinnerConsumer.Consumer>
                            )}
                        </RedirectionSetter>
                    </Layout>
                </SpinnerPortal>
            }
        </Fragment>
    );
}
