//@ts-check

import React from 'react';
import propTypes from 'prop-types';
import {
    Cognito,
} from '@testamento/react-components';
import {
    useSelector,
} from 'react-redux';

import {
    getUserAuthorization,
} from '../utils/user';
import store from '../store';
import {
    hideLoader,
} from '../store/actions';
import {
    consumeToken,
} from '../store/postAuthentication';
import {
    Landing,
} from '../components/Landing';

const {
    useCognitoValuesWatcher,
    useCognitoAuth,
} = Cognito;

const useValidatedUser = () => {
    const [isValidated, setValidation] = React.useState(false);
    const Auth = useCognitoAuth();
    const validatedToken = useSelector(state => state.consumeTokenState);
    const {
        user,
    } = useCognitoValuesWatcher(['user'], 'userDispatch');

    React.useEffect(() => {
        if (
            user &&
            user.userDataKey
        ) {
            // Promise here will use validatedToken value
            consumeToken(
                getUserAuthorization(user),
                localStorage.getItem('givenToken')
            ).then((successfullTokenValidation) => {
                if (!successfullTokenValidation) {
                    Auth.signOut({ global: true });
                }
            });
        }
    }, [user]);

    React.useEffect(() => {
        if (
            user &&
            user.userDataKey &&
            validatedToken
        ) {
            setValidation(true);
        }
    }, [user, validatedToken]);
    return {
        isValidated,
        user,
    };
};
/**
 *
 * @param {*} props
 * @returns
 */
export default function User(props) {
    const { isValidated, user } = useValidatedUser();
    const { PageComponent, PageWrapper, ...data } = props;

    store.dispatch(hideLoader());

    if ((!isValidated || user === 'visitor')) {
        return <Landing {...data} />;
    }
    if (PageWrapper) {
        return (
            <PageWrapper>
                <PageComponent {...data} />
            </PageWrapper>
        );
    }

    return (
        <PageComponent {...data} />
    );
}
User.propTypes = {
    PageComponent: propTypes.any.isRequired,
    PageWrapper: propTypes.any,
};
