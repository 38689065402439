import i18n from 'i18next';
import {
    initReactI18next,
} from 'react-i18next';
import {
    logger,
} from '@testamento/design-system/dist/lib';
import XHR from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

import {
    i18nLoadPath,
    buildWhitelistedLangs,
    LANG_NAMESPACES,
} from './utils';
import parameters from '../parameters.json';

export const COOKIE_LANG_NAME = 'lang';
export const FRENCH_LANG = 'fr';

export const defaultToFr = {
    name: 'defaultToFr',
    lookup: () => (FRENCH_LANG),
};

const debug = logger('i18n:utils');

const detector = new LanguageDetector();
detector.addDetector(defaultToFr);

i18n.on('languageChanged', function () {
    debug(`i18n language changed to ${i18n.language}`);
});

const supportedLangs = ['fr', 'en', 'ja'];

export const configI18n = (partner) => {
    i18n
        .use(detector)
        .use(XHR)
        .use(initReactI18next)
        .init({
            whitelist: buildWhitelistedLangs(partner, supportedLangs),
            nonExplicitWhitelist: true,
            load: 'languageOnly',
            fallbackLng: 'fr',
            ns: LANG_NAMESPACES,
            debug: false,
            detection: {
                order: ['cookie', 'navigator', 'defaultToFr'],
                lookupCookie: COOKIE_LANG_NAME,
                caches: ['cookie'],
                cookieOptions: { secure : true },
            },
            interpolation: {
                escapeValue: false,
            },
            react: {
                suspense: true,
            },
            backend: {
                crossDomain: true,
                queryStringParams: { version: process.env.VERSION },
                loadPath: i18nLoadPath.bind(this, partner, parameters.translation_url),
            },
        });
    return i18n;
};

export default i18n;
