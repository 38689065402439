//@ts-check

import React from 'react';
import {
    useSelector,
} from 'react-redux';
import {
    useTranslation,
} from 'react-i18next';
import styled from 'styled-components';
import {
    Typography,
    Toolbar,
} from '@testamento/design-system';

import LogOut  from '../../icons/Logout';
import Profile from '../../icons/Profile';
import {
    useMatomo,
} from '@jonkoops/matomo-tracker-react';
import {
    RedirectionSetter,
} from '../ConnectedPage';
import {
    SECURE_PROFILE_PATH,
} from '../../constants';

const NavigationLinkLayout = styled.figure`
    display: flex;
    cursor: pointer;
    align-items: center;

    svg {
        flex-grow: initial;
        width: 2.4rem;
        margin-right: .8rem;
    }

    figcaption {
        color: black;
    }

    @media only screen and (max-width: 672px){
        svg {
            margin-right: 0rem;
        }

        figcaption {
            display: none;
        }
    }
`;

const ToolbarItem = styled(Toolbar.Item)`
    margin-right: 2.4rem;

    @media only screen and (max-width: 672px){
        margin-right: 1.6rem;
    }
`;

const ToolbarLogotype = styled(Toolbar.Logotype)`
    max-width: 80%;
    max-height : 56px;
`;

const RemovePaddingToolbar = styled(Toolbar)`
@media(max-width : 672px){
    padding : 20px 14px;
}
`;

export const Navigation = () => {
    const { t } = useTranslation();
    const { trackEvent } = useMatomo();
    const config = useSelector(state => state.config);
    const { global = {} } = config;
    const { logoFullResolution } = global;

    return (
        <RemovePaddingToolbar>
            <Toolbar.Frame>
                <Typography.Link
                    onClick={() => {
                        window.location.pathname = '/';
                    }}
                >
                    <NavigationLinkLayout>
                        <ToolbarLogotype
                            src={logoFullResolution}
                            alt={t('logo_alternative_text')}
                        />
                    </NavigationLinkLayout>
                </Typography.Link>
            </Toolbar.Frame>
            <Toolbar.Frame>
                <Toolbar.List>
                    <ToolbarItem>
                        <RedirectionSetter>
                            {(setRedirectionPath) => (
                                <Typography.Link
                                    onClick={() => {
                                        setRedirectionPath(
                                            SECURE_PROFILE_PATH()
                                        );
                                    }}
                                >
                                    <NavigationLinkLayout>
                                        <Profile />
                                        <figcaption>
                                            {t('navigation.my-account')}
                                        </figcaption>
                                    </NavigationLinkLayout>
                                </Typography.Link>
                            )}
                        </RedirectionSetter>
                    </ToolbarItem>
                    <ToolbarItem>
                        <Typography.Link
                            onClick={() => {
                                trackEvent({ category: 'Signout', action: 'click-signout-btn' });
                                window.location.href = '/logout';
                            }}
                        >
                            <NavigationLinkLayout>
                                <LogOut />
                                <figcaption>
                                    {t('auth.logout')}
                                </figcaption>
                            </NavigationLinkLayout>
                        </Typography.Link>
                    </ToolbarItem>
                </Toolbar.List>
            </Toolbar.Frame>
        </RemovePaddingToolbar>
    );
};
