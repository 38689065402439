//@ts-check

import React from 'react';
import propTypes from 'prop-types';
import {
    useTranslation,
} from 'react-i18next';
import {
    useMatomo,
} from '@jonkoops/matomo-tracker-react';

import DefaultError from './DefaultError';

/**
 *
 * @param {*} props
 * @returns
 */
export default function ResetPasswordError({ colorBackground }) {
    const { t } = useTranslation((['errors']));
    const { trackEvent } = useMatomo();
    trackEvent({ category: 'ResetPasswordErrorPage', action: 'reset-password-error' });

    return (
        <DefaultError
            title={t('reset_password_error_page.title')}
            message={t('reset_password_error_page.content')}
            action={t('reset_password_error_page.cta')}
            colorBackground={colorBackground} />
    );
}

ResetPasswordError.propTypes = {
    colorBackground: propTypes.string,
};
