import React from 'react';
import propTypes from 'prop-types';
import {
    Button, 
} from '@testamento/design-system';
import styled from 'styled-components';
import {
    useSelector, 
} from 'react-redux';

import {
    styleObjectToCss,
    applyIfAny,
    fullWidthOnBreakPoint,
    applyColorScheme, 
} from '../../../lib/styled';
import ButtonLike from './ButtonLike';

const styles = {
    margin: '1.3rem 0',
    padding: '1.3rem',
    width: '100%',
    borderRadius: '0.5rem',
    fontSize: '1.6rem',
    lineHeight: '3.3rem',
    fontWeight: '400',
    fontFamily: 'Roboto',
    border: '0.1rem solid',
};

const ButtonWithIcon = styled(Button.SocialLoginButton)`
    ${(Object.keys(styles)
        .map(styleObjectToCss(styles))
        .join('')
    )};
`;

export const ButtonWithIconVariant = ({
    children,
    bgColor,
    borderColor,
    color,
    onClick,
    as,
    to,
    additionalStyles,
}) => {
    const config = useSelector(state => state.config);
    const { global = {} } = config;
    return (
        <PartnerButton
            backgroundColor={bgColor ? bgColor: global.colorBackground}
            color={color?color:'white'}
            style={{ borderColor: borderColor ? borderColor : '#9c9c9c' }}
            {...additionalStyles}
        >
            {(as
                ? <ButtonLike {...{ Component: as, styles, to, onClick }}>
                    {children}
                </ButtonLike>
                : <ButtonWithIcon {...{ to, onClick }}>
                    {children}
                </ButtonWithIcon>
            )}
        </PartnerButton>

    );
};
ButtonWithIconVariant.propTypes = {
    children: propTypes.node,
    bgColor: propTypes.string,
    borderColor: propTypes.string,
    color: propTypes.string,
    onClick: propTypes.func,
    as: propTypes.string,
    to: propTypes.string,
    additionalStyles: propTypes.string,
};

export const PartnerButton = styled.span`
    ${props => applyIfAny('flexGrow', props)}
    ${props => applyIfAny('width', props)}
    button, a {
        ${props => fullWidthOnBreakPoint(props)}
        &>span {
            width: fit-content;
        }
        display: inline-block;
        text-decoration: none;
        ${props => applyColorScheme(props)}
    }
`;
