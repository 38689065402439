import {
    logger,
} from '@testamento/design-system/dist/lib';

import {
    USERNAME_EXISTS_EXCEPTION,
    USER_NOT_FOUND_EXCEPTION,
    NOT_AUTHORIZED_EXCEPTION,
    INVALID_PARAMETER_EXCEPTION,
    INVALID_PASSWORD_EXCEPTION,
} from '../constants';

const debug = logger('planner:cognito:error-hub');
export const handleErrorMessagesFromCognito = (code) => {
    debug(`received: ${code}`);
    switch(code){
        case USERNAME_EXISTS_EXCEPTION:
            return 'auth.signup.error.userExist';
        case USER_NOT_FOUND_EXCEPTION:
        case NOT_AUTHORIZED_EXCEPTION:
        case INVALID_PARAMETER_EXCEPTION:
            return 'auth.error.cognito_error';
        case INVALID_PASSWORD_EXCEPTION:
            return 'auth.signup.error.notValidPassword';
        default:
            return 'auth.error.cognito_error';
    }
};
