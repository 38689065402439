import React from 'react';
import styled from 'styled-components';
import {
    Typography,
} from '@testamento/design-system';
import propTypes from 'prop-types';

const circleRadius = 1.8;

const TypographyBody = styled(Typography.Body)`
    margin: 0 0 20px;
`;
const TextMention = styled.small`
    font-size: 1.2rem;
`;
const Container = styled.ol`
    list-style: none;
    counter-reset:mkt-counter;
    padding: 0;
    margin: 56px 0 0 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
`;

const Item = styled.li`
    counter-increment: mkt-counter;
    max-width: 33.3%;
    padding-left: 9px;

    &:first-of-type {
        padding-left: 0;
    }

    p {
        line-height: 1.9em;
    }

    & h6::before {
        content: counter(mkt-counter) '';
        width: ${2 * circleRadius}rem;
        display: inline-block;
        height: ${2 * circleRadius}rem;
        border-radius: 50%;
        font-size: 1.9rem;
        color: ${({ backgroundColor, color }) => (backgroundColor && color)
        ? color
        : 'white'
};
        line-height: ${2 * circleRadius}rem;
        text-align: center;
        background: ${({ backgroundColor, color }) => (backgroundColor && color)
        ? backgroundColor
        : '#0062FF'
};
        margin-right: 9px;
    }

    @media(max-width: 1056px) {
        max-width: 100%;
        padding-left: 0;
        padding-bottom: 36px;
    }
`;

const ListContent = styled(Typography.Body)`
    margin-left: 44px;
`;

const LandingForwarded = (prop) => {
    return ![
        'fontSize',
        'fontColor'
    ].includes(prop);
};

const Header = styled(Typography.HeadingXl).withConfig({
    shouldForwardProp: LandingForwarded,
})`
    //fontSize and fontColor must always been provided
    font-size: ${props => props.fontSize};
    color: ${props => props.fontColor};
    line-height: 1.2em;
    text-shadow: .3rem .3rem .5rem rgba(0,0,0,.3);
    margin-bottom: 2.3rem;
`;

const validateFontSize = (fontSize) => /^\d+(?:\.\d+)?(?:px|rem)$/.test(fontSize);
const validateFontColor = (fontColor) => /^#[0-9a-f]{6}$/.test(fontColor);
const LandingTitle = ({ children, ...props }) => {
    const fontSize = (props['font-size'] && validateFontSize(props['font-size'])
        ? props['font-size']
        : '6.8rem'
    );
    const fontColor = (props['font-color'] && validateFontColor(props['font-color'])
        ? props['font-color']
        : '#FFFFFF'
    );

    return (
        <Header {...{ fontSize, fontColor }}>
            {children}
        </Header>
    );
};
LandingTitle.propTypes = {
    children: propTypes.node,
    'font-size': propTypes.string,
    'font-color': propTypes.string,
};

const HeaderDesc = styled(Typography.Body).withConfig({
    shouldForwardProp: LandingForwarded,
})`
    font-size: ${props => props.fontSize};
    line-height: 1.2em;
    color: ${props => props.color};
    text-shadow: .3rem .3rem .5rem rgba(0,0,0,.3);
`;

const LandingHeaderDesc = ({ children, ...props }) => {
    const fontSize = (props['font-size'] && validateFontSize(props['font-size'])
        ? props['font-size']
        : '6.8rem'
    );
    const fontColor = (props['font-color'] && validateFontColor(props['font-color'])
        ? props['font-color']
        : '#FFFFFF'
    );

    return (
        <HeaderDesc {...{ fontSize, fontColor }} as="section">
            {children}
        </HeaderDesc>
    );
};
LandingHeaderDesc.propTypes = {
    children: propTypes.node,
    'font-size': propTypes.string,
    'font-color': propTypes.string,
};


export const mappings = {
    'page-title': LandingTitle,
    'page-description': LandingHeaderDesc,
    p: function p({ children }) {
        return (
            <TypographyBody>{children}</TypographyBody>
        );
    },
    br: function br() {
        return (
            <br />
        );
    },
    title: Typography.HeadingLg,
    ol: Container,
    li: Item,
    small: TextMention,
    'item-title': Typography.HeadingXxs,
    'item-description': ListContent,
};
