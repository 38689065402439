
import React, {
    useState,
    createRef,
    useEffect,
} from 'react';
import propTypes from 'prop-types';
import {
    decode,
} from 'he';
import styled, {
    css,
    keyframes,
} from 'styled-components';
import {
    useTranslation,
} from 'react-i18next';
import {
    Typography,
    FormControl,
} from '@testamento/design-system';
import {
    FragmentStringToJSX,
} from '@testamento/design-system/dist/lib';

const LinkWrapper = styled(Typography.Body)`
    text-align: left;
    font-size: 1.3rem;
    line-height: 1.6rem;
    margin: 0 0 2em;
    padding: 0;
`;

const notify = keyframes`
    0% {
        background-color: transparent;
        margin: 0;
        padding: 0;
        border-radius: 0px;
    }
    33% {
        margin: -10px;
        padding: 10px;
        border-radius: 10px;
        background-color: lightgreen;
    }
    66% {
        margin: -10px;
        padding: 10px;
        border-radius: 10px;
        background-color: lightgreen;
    }
    100% {
        margin: 0;
        background-color: transparent;
        border-radius: 0px;
    }
`;
const animation = css`
    animation: ${notify} 2s ease;
`;
const TypographyBody = styled.p`
`;
const AnimationParent = styled.div`
    margin-bottom: 2rem;
`;
const ActionSummary = styled.div`
    &.notify {
        ${animation};
    }
    p {
        font-size: 1.6rem;
        text-align: left;
        margin-bottom: 1em;
        line-height: 1.2em;
        font-weight: 400;
    }
    p:last-of-type {
        margin-bottom: 0;
    }
    animation-name: none;
`;
const b = ({ children }) => {
    return (
        <strong>{children}</strong>
    );
};
b.propTypes = {
    children: propTypes.node,
};

const { FieldClearer } = FormControl;

/**
 *
 * @param {*} props
 * @param {*} props.children
 *
 * @returns
 */
export default function ConfirmationCode({
    children,
    setCognitoErrorCode,
    resendPromise,
    resentTranslationKey,
    userEmail,
}) {
    const { t } = useTranslation();
    const [isConfirmationCodeResent, setConfirmationCodeResent] = useState(0);
    const actionSummaryMessage = (isConfirmationCodeResent === 0
        ? 'auth.confirmation_code.initial_action_summary'
        : 'auth.confirmation_code.resend_action_summary'
    );
    // animation related effect
    const messageBox = createRef();
    useEffect(() => {
        let timeoutId;
        if (messageBox.current && isConfirmationCodeResent > 0) {

            messageBox.current.removeAttribute('role');
            messageBox.current.classList.remove('notify');
            /** below timeout is mandatory to ensure all dom modification will be executed in distinct react reconciliation phase  */
            timeoutId = setTimeout(
                () => {
                    if (messageBox.current && isConfirmationCodeResent > 0) {
                        messageBox.current.setAttribute('role', 'alert');
                        messageBox.current.classList.add('notify');
                    }
                },
                100
            );
        }
        return () => {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
        };
    }, [messageBox, isConfirmationCodeResent]);

    return (
        <React.Fragment>
            <AnimationParent>
                <ActionSummary ref={messageBox} >
                    <TypographyBody>
                        <FragmentStringToJSX
                            content={decode(t(actionSummaryMessage, { emailAddress: userEmail }))}
                            mappings={{
                                b,
                            }}
                        />
                    </TypographyBody>
                </ActionSummary>
            </AnimationParent>
            {children}
            <FieldClearer>
                {(clear) => (
                    <LinkWrapper>
                        <a href={'/#'} onClick={(e) => {
                            e.preventDefault();
                            clear('confirmationCode', '');
                            resendPromise(userEmail).then(
                                () => {
                                    setConfirmationCodeResent((prev) => {
                                        return prev + 1;
                                    });
                                }
                            ).catch(
                                setCognitoErrorCode
                            );
                        }}>
                            {t(resentTranslationKey)}
                        </a>
                    </LinkWrapper>
                )}
            </FieldClearer>
        </React.Fragment>
    );
}
ConfirmationCode.propTypes = {
    children: propTypes.node,
    resentTranslationKey: propTypes.string,
    userEmail: propTypes.string,
    resendPromise: propTypes.func,
    setCognitoErrorCode: propTypes.func,
};
