//@ts-check

import React from 'react';
import {
    useTranslation,
} from 'react-i18next';
import propTypes from 'prop-types';
import {
    Button,
    Icon,
} from '@testamento/design-system';
import {
    useMatomo,
} from '@jonkoops/matomo-tracker-react';
import {
    Cognito,
} from '@testamento/react-components';
import styled from 'styled-components';
import {
    useSelector,
} from 'react-redux';

const {
    CognitoInteraction,
} = Cognito;

const SpacedButton = styled(Button.SocialLoginButton)`
    margin : 1rem 0;
    white-space: nowrap;
    @media(max-width : 450px) {
        padding : 1rem 0.5rem;
    }
`;

const DEFAULT_PROVIDER = (provider) => {
    const lower = provider.toLowerCase();
    return (
        {
            provider : null,
            matomoAction : 'click-' + lower + '-auth',
            ProviderIcon : null,
        }
    );
};

const { GoogleSignin, FacebookSignin, AssurentoSignin } = Icon;

const providerProperties = {
    'Google' : {
        provider : 'Google',
        matomoAction : 'click-google-auth',
        ProviderIcon : GoogleSignin,
    },
    'Facebook' : {
        provider : 'Facebook',
        matomoAction : 'click-facebook-auth',
        ProviderIcon : FacebookSignin,
    },
    'Assurento-saml' : {
        provider : 'Assurento-saml',
        matomoAction : 'click-assurento-auth',
        ProviderIcon : AssurentoSignin,
    },
};
const FederatedSignin = ({ customState, Auth }) => {
    const { trackEvent } = useMatomo();
    const { t } = useTranslation();
    const config = useSelector(state => state.config);
    const { global = {} } = config;
    const { authProviders } = global;

    const onClick = ({ provider, matomoAction }) => {
        trackEvent({ category: customState === 'signin' ? 'Signin' : 'Signup', action: matomoAction });
        if (provider) {
            Auth.federatedSignIn({
                provider,
                customState,
            });
        }
    };


    return (
        <>
            { authProviders
                ? authProviders.filter(provider => provider !== 'Cognito').map((provider, key) => {
                    const { ProviderIcon, ...rest } = providerProperties[provider] ? providerProperties[provider] : DEFAULT_PROVIDER(provider);
                    return (
                        <SpacedButton key={key} onClick={() => onClick(rest)}>
                            {ProviderIcon ? <ProviderIcon/> : <></>}
                            {t('auth.' + customState + '.'  + provider.toLowerCase())}
                        </SpacedButton>
                    );
                })
                : null
            }
        </>
    );
};

FederatedSignin.propTypes = {
    customState: propTypes.string.isRequired,
    Auth: propTypes.any,
};

export const OAuthButton = ({ customState }) => (
    <CognitoInteraction>
        {(Auth) => <FederatedSignin {...{ Auth, customState }}/>}
    </CognitoInteraction>
);

OAuthButton.default = {
    customState: 'signin',
};

OAuthButton.propTypes = {
    customState: propTypes.string,
};

export default OAuthButton;
