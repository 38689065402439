import React from 'react';
import propTypes from 'prop-types';
/**
 *
 * @param {Object} props
 * @param {string} fillColor
 * @returns
 */
export default function Profile({ fillColor }) {
    return <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0)">
            <path d="M23.99 12.40C23.99 5.75 18.63 0.38 11.99 0.38C5.36 0.38 -0.00 5.75 -0.00 12.40C-0.00 18.98 5.32 24.41 11.99 24.41C18.65 24.41 23.99 19.01 23.99 12.40ZM11.99 1.79C17.84 1.79 22.59 6.55 22.59 12.40C22.59 14.54 21.95 16.59 20.77 18.33C16.04 13.24 7.96 13.23 3.22 18.33C2.04 16.59 1.40 14.54 1.40 12.40C1.40 6.55 6.15 1.79 11.99 1.79ZM4.09 19.46C8.30 14.73 15.69 14.73 19.90 19.46C15.67 24.19 8.32 24.19 4.09 19.46Z" fill={fillColor}/>
            <path d="M11.99 13.10C14.32 13.10 16.21 11.21 16.21 8.88V7.47C16.21 5.14 14.32 3.24 11.99 3.24C9.67 3.24 7.78 5.14 7.78 7.47V8.88C7.78 11.21 9.67 13.10 11.99 13.10ZM9.18 7.47C9.18 5.91 10.44 4.65 11.99 4.65C13.55 4.65 14.81 5.91 14.81 7.47V8.88C14.81 10.43 13.55 11.69 11.99 11.69C10.44 11.69 9.18 10.43 9.18 8.88V7.47Z" fill={fillColor}/>
        </g>
        <defs>
            <clipPath id="clip0">
                <rect width="24" height="24.03" fill="white" transform="translate(-0.00 0.38)"/>
            </clipPath>
        </defs>
    </svg>;
}

Profile.propTypes = {
    fillColor: propTypes.string,
};

Profile.defaultProps = {
    fillColor: 'black',
};
