
/**
 * Partner related constants
 */
export const DEFAULT_PARTNER = 'testamento';

/**
 * Utility for env prefix
 */

export const getEnvPrefix = (env, separator) => 'prod' === env ? '' : `${env}${separator}`;

/**
 *
*/

export const APP_ENV = process.env.REACT_APP_APP_ENV || 'dev';


/**
 * Applications
 */
export const BC_API_URL = process.env.REACT_APP_BC_API_URL || `https://${getEnvPrefix(APP_ENV, '.')}bc.testamento.fr`;
export const BS_API_URL = process.env.REACT_APP_BS_API_URL || `https://${getEnvPrefix(APP_ENV, '.')}bs.testamento.fr`;

export const CONSUME_SESSION_API = token => `${BC_API_URL}/api/session/${token}`;

/**
 * Ressources
 */
export const RESOURCES_URL = process.env.REACT_APP_RESOURCES_URL || `https://${getEnvPrefix(APP_ENV, '-')}resources.testamento.fr`;
export const TRANSLATION_URL = `${RESOURCES_URL}/translation/portal/{lang}/{template}/translation.json`;

export const PARTNER_CONFIG = (partner) => `${RESOURCES_URL}/white-labeling/${partner || 'default'}/json/portal.json`;
export const BACKGROUND_IMG = (partner) => `${RESOURCES_URL}/white-labeling/${partner || 'default'}/img/portal-background.png`;

/**
 * Error codes for Cognito
 */

export const USER_NOT_CONFIRMED_EXCEPTION = 'UserNotConfirmedException';

export const EXPIRED_EXCEPTION = 'ExpiredCodeException';

export const CODE_MISMATCH_EXCEPTION = 'CodeMismatchException';

export const USERNAME_EXISTS_EXCEPTION = 'UsernameExistsException';

export const USER_NOT_FOUND_EXCEPTION = 'UserNotFoundException';

export const NOT_AUTHORIZED_EXCEPTION = 'NotAuthorizedException';

export const CODE_DELIVERY_FAILURE_EXCEPTION = 'CodeDeliveryFailureException';

export const INTERNAL_ERROR_EXCEPTION = 'InternalErrorException';

export const INVALID_EMAIL_ROLE_ACCESS_POLICY_EXCEPTION = 'InvalidEmailRoleAccessPolicyException';

export const INVALID_LAMBDA_RESPONSE_EXCEPTION = 'InvalidLambdaResponseException';

export const INVALID_PARAMETER_EXCEPTION = 'InvalidParameterException';

export const INVALID_PASSWORD_EXCEPTION = 'InvalidPasswordException';

export const INVALID_SMS_ROLE_ACCESS_POLICY_EXCEPTION = 'InvalidSmsRoleAccessPolicyException';

export const INVALID_SMS_ROLE_TRUST_RELATIONSHIP_EXCEPTION = 'InvalidSmsRoleTrustRelationshipException';

export const RESOURCE_NOT_FOUND_EXCEPTION = 'ResourceNotFoundException';

export const TOO_MANY_REQUESTS_EXCEPTION = 'TooManyRequestsException';

export const UNEXPECTED_LAMBDA_EXCEPTION = 'UnexpectedLambdaException';

export const USER_LAMBDA_VALIDATION_EXCEPTION = 'UserLambdaValidationException';
/**
 * Borrowed from https://github.com/aws-amplify/amplify-js/blob/master/packages/amplify-ui-components/src/common/types/auth-types.ts
 */
export const AuthState = {
    SignUp: 'signup',
    SignOut: 'signout',
    SignIn: 'signin',
    Loading: 'loading',
    SignedOut: 'signedout',
    SignedIn: 'signedin',
    SigningUp: 'signingup',
    ConfirmSignUp: 'confirmSignUp',
    confirmingSignUpCustomFlow: 'confirmsignupcustomflow',
    ConfirmSignIn: 'confirmSignIn',
    confirmingSignInCustomFlow: 'confirmingsignincustomflow',
    VerifyingAttributes: 'verifyingattributes',
    ForgotPassword: 'forgotpassword',
    ResetPassword: 'resettingpassword',
    SettingMFA: 'settingMFA',
    TOTPSetup: 'TOTPSetup',
    CustomConfirmSignIn: 'customConfirmSignIn',
    VerifyContact: 'verifyContact',
};
// token based signup strategy
export const SIGNUP_UNAVAIBLE_WITHOUT_TOKEN = 'signup unavailable without token';

// secure path constants
export const SECURE_PROFILE_PATH = (lang = 'fr') => `/${lang}/myprofile`;
export const SECURE_ACCOUNT_PARAMETERS_PATH = (lang = 'fr') => `/${lang}/myprofile/account-parameters`;

export const AMPLIFY_LOG_LEVELS = [
    'ERROR',
    'WARN',
    'INFO',
    'DEBUG',
    'VERBOSE'
];
export const AMPLIFY_LOG_LEVEL = 'AMPLIFY_LOG_LEVEL';

// Routing path
export const AUTHENTICATED_PATHES = Object.freeze({
    CHANGE_PASSWORD: '/reset-password',
    LOGOUT: '/logout',
    DELETE_ACCOUNT: '/delete',
});
