import React, {
    createContext, useState,
} from 'react';
import propTypes from 'prop-types';
import {
    Grid,
} from '@testamento/design-system';

import {
    Navigation,
} from '../components/Navigation';
import {
    SecureRedirection,
} from '../contexts';
const Redirection = createContext(null);
/**
 *
 * @param {Object} props
 * @param {ReactNode} props.children
 * @returns
 */
export default function ConnectedPage ({ children }) {
    const [redirectionPath, setRedirectionPath] = useState(null);
    return <Redirection.Provider value={setRedirectionPath}>
        {(typeof redirectionPath === 'string'
            ?(
                <SecureRedirection backPath={redirectionPath} />
            )
            :(
                <Grid maxFreeze={'true'} style={{ backgroundColor:'white' }}>
                    <Grid.Row>
                        <Grid.Col size={12}>
                            <Navigation />
                        </Grid.Col>

                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Col size={12}>
                            {children}
                        </Grid.Col>
                    </Grid.Row>
                </Grid>
            )
        )}
    </Redirection.Provider>;
}
ConnectedPage.propTypes = {
    children: propTypes.node,
};
export const RedirectionSetter = Redirection.Consumer;
