import {
    applyMiddleware, createStore, 
} from 'redux';
import {
    createLogger, 
} from 'redux-logger';
import thunk from 'redux-thunk';
import promise from 'redux-promise-middleware';

import Journal from './Journal';

const store = createStore(Journal, applyMiddleware.apply(null,
    [
        promise,
        thunk,
        ((process.env.NODE_ENV !== 'test') ? createLogger({ collapsed: true }) : undefined)
    ].filter(value => value !==undefined)
));

export default store;
