/** @deprecated */

import React, {
    createContext,
} from 'react';
import he from 'he';
import propTypes from 'prop-types';
import {
    v4 as uuid,
} from 'uuid';

import {
    simpleXmlParser,
} from '../parser';

const TranslationXmlContext = createContext(null);
const TranslationXmlConsumer = TranslationXmlContext.Consumer;
const TranslationChild = ({ renderer, childs, parentKey }) => {
    return <TranslationXmlConsumer>
        {(mappings) => {
            const {
                rendererName,
                Renderer,
            } = (renderer
                ? ({
                    rendererName: renderer,
                    Renderer: mappings[renderer],
                })
                : ({
                    rendererName: 'React.Fragment',
                    Renderer: React.Fragment,
                })
            );

            return (
                <Renderer
                    key={`${parentKey}_${rendererName}`}
                >
                    {childs.map(
                        (props, key) => (typeof props === 'string'
                            ? <React.Fragment key={`${parentKey}_${key}`}>
                                {he.decode(props)}
                            </React.Fragment>
                            : <TranslationChild
                                key={`${parentKey}_${key}`}
                                {...props}
                                {...{
                                    parentKey: `${parentKey}_${key}`,
                                }}
                            />
                        )
                    )}
                </Renderer>
            );
        }}
    </TranslationXmlConsumer>;
};
TranslationChild.propTypes = {
    renderer: propTypes.string,
    childs:propTypes.array,
    parentKey: propTypes.string,
};

/**
 *
 * @param {Object} props
 * @param {Array} props.content
 * @param {Object} props.mappings
 * @returns ReactElement
 */
export default function TranslationLayout (
    {
        content,
        mappings,
    } = { mappings: {} }
) {
    const rootTranslationKey = uuid();
    const childsCollection = simpleXmlParser(content);
    return <TranslationXmlContext.Provider
        value={mappings}
    >
        <TranslationChild
            childs={childsCollection}
            parentKey={`translation_${rootTranslationKey}`}
        />
    </TranslationXmlContext.Provider>;
}
TranslationLayout.propTypes = {
    content: propTypes.string,
    mappings: propTypes.object,
};
