/**
 *
 * @param {Object} user
 * @returns string
 */
export const getUserAuthorization = (user) => {
    const { signInUserSession = {} } = user;
    const { idToken = {} } = signInUserSession;
    const { jwtToken } = idToken;
    return jwtToken;
};
