
import React from 'react';
import propTypes from 'prop-types';
import {
    FormControl,
} from '@testamento/design-system';
import {
    signUpEmailRule,
    mandatoryCheckRules,
} from '../../../lib/validator';

import {
    FieldLabel,
    FieldWrapper,
    FormCell,
    ErrorRenderer,
} from './styles';

import Remote, {
    useRemoteState,
} from '../../Forms';


const {
    Field,
    ControlledInput,
    DefaultInputField,
    useValidationErrors,
    useValidationState,
    useFieldsError,
    useFieldsValue,
} = FormControl;

const ForgotPassword = ({
    PreForm,
    PostForm,
    initialUserEmailValue,
    userEmailLabelMessage,
    userEmailPlaceholderMessage,
    userEmailErrorMessage,
}) => {
    const dispatch = useRemoteState();

    return (
        <FormControl>
            {PreForm
                ? <PreForm formDispatch={dispatch} />
                : null
            }
            <Field Element={FieldWrapper}>
                <FieldLabel as="p">{userEmailLabelMessage}</FieldLabel>
                <FormCell>
                    <ControlledInput
                        ControlledElement={DefaultInputField}
                        name="userEmail"
                        type="text"
                        placeholder={userEmailPlaceholderMessage}
                        value={initialUserEmailValue}
                        autoWidth={'true'}
                        validations={[
                            (value) => (mandatoryCheckRules(value)
                                ? ''
                                : userEmailErrorMessage
                            ),
                            (value) => (signUpEmailRule(value)
                                ? ''
                                : userEmailErrorMessage
                            )
                        ]}
                    />
                </FormCell>
                <ErrorRenderer name="userEmail">
                    {userEmailErrorMessage}
                </ErrorRenderer>
            </Field>
            {PostForm
                ? <PostForm  formDispatch={dispatch} />
                : null
            }
        </FormControl>
    );
};
ForgotPassword.defaultProps = {
    PreForm: null,
    PostForm: null,
};
ForgotPassword.propTypes = {
    PreForm: propTypes.any,
    PostForm: propTypes.any,

    initialUserEmailValue: propTypes.string,
    userEmailLabelMessage: propTypes.string,
    userEmailPlaceholderMessage: propTypes.string,
    userEmailErrorMessage: propTypes.string,
};


/**
 *
 * @param {*} param0
 */
export default function ForgotPasswordWrapper ({ children, ...props }) {
    return (
        <Remote>
            <ForgotPassword {...props}>
                {children}
            </ForgotPassword>
        </Remote>
    );
}
ForgotPasswordWrapper.propTypes = {
    children: propTypes.node,
};

export {
    useValidationErrors,
    useValidationState,
    useFieldsError,
    useFieldsValue
};
