import {
    hsl, 
} from 'd3-color';

export const camelToSnakeCase = (value) => value.replace(
    /[A-Z]/g,
    (uppercase) =>  `-${uppercase.toLowerCase()}`
);

export const styleObjectToCss = (container) => (key) => (
    `${camelToSnakeCase(key)}: ${container[key]};`
);
export const applyIfAny = (key, container) => (container[key]
    ? `${camelToSnakeCase(key)}: ${container[key]};`
    : ''
);

export const applyGapOn = ({ gap }, targetMargin) => (gap
    ? `${targetMargin}: gap;`
    :''
);

export const fullWidthOnBreakPoint = (props) => (props.flexFullWidth
    ? `
        @media(max-width : ${props.flexBreakSize}px) {
            width : 100%;
        }
    `
    : ''
);

export const applyColorScheme = ({ backgroundColor, color, modifier }) => {
    const hoverColor = hsl(backgroundColor);
    hoverColor.l -= .1;

    if (backgroundColor && color && modifier !== 'secondary') {
        return `
            background-color: ${backgroundColor};
            color: ${color};
            :hover {
                background-color: ${hoverColor};
            }
        `;
    } else if (modifier === 'secondary') {
        return `
        background-color: transparent;
        color: ${backgroundColor};
        border: 2px solid ${backgroundColor};
        :hover {
            color: white;
            background-color: ${backgroundColor};
        }
    `;
    }

    return '';
};
