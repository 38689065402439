//@ts-check

import React from 'react';
import ReactDOM from 'react-dom';
import {
    Provider,
} from 'react-redux';
import {
    Cognito,
    ConfigurationLoader,
} from '@testamento/react-components';
import{
    logger,
} from '@testamento/design-system/dist/lib';

import {
    SIGNUP_UNAVAIBLE_WITHOUT_TOKEN,
    AMPLIFY_LOG_LEVELS,
    AMPLIFY_LOG_LEVEL,
} from './constants';
import './index.css';
import AppWrapper from './App';
import store from './store';
import {
    hideLoader,
} from './store/actions';
import {
    ErrorBoundary,
} from './utils/react';
import {
    getPartnerFromDomain,
} from './utils/utils';
import parameters from './parameters.json';
import LoaderReact from './components/LoaderReact.jsx';

// mutate aws-amplify LOG_LEVEL
(() => {
    if (AMPLIFY_LOG_LEVELS.includes(localStorage.getItem(AMPLIFY_LOG_LEVEL))) {
        window.LOG_LEVEL = localStorage.getItem(AMPLIFY_LOG_LEVEL);
    }
})();

const { ConfigurationConsumer } = ConfigurationLoader;
const debug = logger('planner:init');

const signupToken = (new URLSearchParams(window.location.search)).get('token');
const partnerName = getPartnerFromDomain(window.location.host);

export const signupAvailable = (token) => (success = {}, failures = {}) => {
    const { properties = {} } = success;
    const { appSettings = {} } = properties;
    const { planner = {} } = appSettings;

    if (token !== null) {
        if (
            !planner.disableSignupTokenGuard &&
            failures.tokenValidation
        ) {
            debug(`partner ${partnerName} needs token validation but token validation returns an error with message "${failures.tokenValidation}"`);
        }

        if (
            planner.disableSignupTokenGuard &&
            failures.tokenValidation
        ) {
            debug(`no token validation should be taken into account for partner ${partnerName}, but a token has been provided and is invalid`);
        }

        if (
            planner.disableSignupTokenGuard &&
            failures.tokenValidation === undefined
        ) {
            debug(`no token validation were needed for partner ${partnerName} and none has been provided, everythings is fine`);
        }

        if (
            !planner.disableSignupTokenGuard &&
            success.tokenValidation
        ) {
            debug(`token ok for partner ${partnerName}`);
        }
    } else {
        if (!planner.disableSignupTokenGuard) {
            debug(`missing token in landing url for ${partnerName} therefore we don't display signup screen`);
            return {
                updates:{
                    tokenValidation: {
                        value: SIGNUP_UNAVAIBLE_WITHOUT_TOKEN,
                    },
                },
            };
        } else {
            debug(`no token in landing url for ${partnerName} and no validation required for this partner, therefore we display signup screen`);
        }
    }

    return {
        updates:{
            tokenValidation: {
                hideFailure: true,
            },
        },
    };
};
if (process.env.NODE_ENV !== 'test') {
    ReactDOM.render(
        <React.StrictMode>
            <Provider store={store}>
                <ConfigurationLoader

                    requirements={{
                        properties: {
                            url: `${parameters.public_api_url}/services/properties/${partnerName}/planner`,
                            headers: {
                                'x-api-key': parameters['x_api_key'],
                            },
                        },
                        ...((token) => {
                            if (token) {
                                return {
                                    tokenValidation: {
                                        url: `${parameters.public_api_url}/partner/validation/${partnerName}/bytoken/${token}`,
                                        headers: {
                                            'Content-Type': 'application/json',
                                        },
                                        mode: 'cors',
                                    },
                                };
                            } else {
                                return { };
                            }
                        })(signupToken),

                        signupAvailable: signupAvailable(signupToken),
                    }}
                    delay={1500}
                    Loader={LoaderReact}
                >
                    <ErrorBoundary>

                        <React.Suspense
                            fallback={
                                <LoaderReact context="application start" />
                            }
                        >
                            <ConfigurationConsumer>
                                {(loader)=> {
                                    const config = loader();

                                    return (
                                        <Cognito
                                            partnerName={getPartnerFromDomain(
                                                window.location.host
                                            )}
                                            checkUserInProgress={() => {
                                                debug('checkUserInProgress hide loader');
                                                store.dispatch(hideLoader());
                                            }}
                                            userPoolId={parameters.cognito_user_pool_id}
                                            region={parameters.cognito_aws_region}
                                            userPoolWebClientId={parameters.cognito_web_client_id}
                                            authDomain={parameters.cognito_domain}
                                            signupAvailable={config.tokenValidation === undefined}
                                        >
                                            <AppWrapper config={config} />
                                        </Cognito>
                                    );
                                }}
                            </ConfigurationConsumer>
                        </React.Suspense>
                    </ErrorBoundary>
                </ConfigurationLoader>
            </Provider>
        </React.StrictMode>,
        document.getElementById('root')
    );
}
