//@ts-check

import React, {
    useEffect,
    useState,
} from 'react';
import propTypes from 'prop-types';
import {
    useTranslation,
} from 'react-i18next';
import {
    Typography,
    Button,
} from '@testamento/design-system';
import {
    useMatomo,
} from '@jonkoops/matomo-tracker-react';
import {
    Cognito,
} from '@testamento/react-components';

import {
    handleErrorMessagesFromCognito,
} from '../../utils/errorCognitoHandling';
import ForgotPasswordForm, {
    useValidationState,
    useFieldsValue,
} from './forms/forgot-password';
import {
    REGISTER_EFFECT,
    UNREGISTER_EFFECT,
    REGISTER_ERROR,
    UNREGISTER_ERROR,
    REQUEST_PENDING,
    REQUEST_SUCCESS,
    REQUEST_ERROR,
    SpacedFlashbag,
} from '../Forms';
import AnimatedSpinner, {
    SpinnerWrapper,
} from '../Block/Spinner/index';
import {
    InputContainer,
} from '../Block/Form';
import {
    signIn,
    resetPassword,
} from '../../store/actions';
import store from '../../store';
import {
    PartnerButton,
} from '../Block/Styling';
import {
    BackLinkContainer,
} from './block';

const {
    CognitoInteraction,
    useCognitoHubDispatch,
} = Cognito;

export const CognitoForgotPassword = ({ colorBackground, Auth }) => {
    const { t } = useTranslation();
    const { trackPageView } = useMatomo();

    React.useEffect(() => {
        trackPageView({
            documentTitle: 'ForgotPassword',
        });
    }, [trackPageView]);

    return (
        <ForgotPasswordForm
            initialUserEmailValue={''}
            userEmailLabelMessage={t('auth.email')}
            userEmailPlaceholderMessage={t('auth.email')}
            userEmailErrorMessage={t('auth.signin.username_hint')}
            PreForm={function PreForm ({ formDispatch }) {
                const [cognitoErrorCode, $cognitoErrorCode] = useState(null);

                useEffect(() => {
                    formDispatch({ type: REGISTER_ERROR, payload:{ effect: $cognitoErrorCode } });
                    return () => {
                        formDispatch({ type: UNREGISTER_ERROR });
                    };
                },[formDispatch, $cognitoErrorCode] );

                return (
                    <>
                        {cognitoErrorCode
                            ? <SpacedFlashbag background='warning' title={t(handleErrorMessagesFromCognito(cognitoErrorCode))} />
                            : null
                        }
                        <InputContainer>
                            <Typography.HeadingXxs>{t('auth.reset.title')}</Typography.HeadingXxs>
                        </InputContainer>
                    </>
                );
            }}
            PostForm={function PostForm ({ formDispatch }) {
                const state = useValidationState();
                const values =  useFieldsValue();
                const [useAuthSpinner, setUseAuthSpinner] = useState(false);
                const hubDispatch = useCognitoHubDispatch();

                useEffect(() => {
                    formDispatch({ type: REGISTER_EFFECT, payload:{ effect: setUseAuthSpinner } });
                    return () => {
                        formDispatch({ type: UNREGISTER_EFFECT });
                    };
                },[formDispatch, setUseAuthSpinner] );

                return (
                    <>
                        <BackLinkContainer
                            onClick={() => store.dispatch(signIn(hubDispatch))}
                        >
                            {t('auth.reset.back')}
                        </BackLinkContainer>
                        <PartnerButton color="#FFFFFF" backgroundColor={ colorBackground }>
                            <Button
                                disabled={ useAuthSpinner ? true : false}
                                onClick={(event) => {
                                    event.preventDefault();
                                    if(state()) {
                                        const {
                                            userEmail,
                                        } = values();
                                        formDispatch({ type: REQUEST_PENDING });

                                        Auth.forgotPassword(userEmail)
                                            .then(
                                                () => {
                                                    formDispatch({ type: REQUEST_SUCCESS });
                                                    store.dispatch(resetPassword(
                                                        userEmail,
                                                        hubDispatch
                                                    ));
                                                },
                                                (forgotPasswordError) => {
                                                    const {
                                                        code,
                                                    } = forgotPasswordError;

                                                    formDispatch({ type: REQUEST_ERROR, payload:{ code } });
                                                }
                                            )
                                            .catch(
                                                (globalError) => {
                                                    const {
                                                        code,
                                                    } = globalError;

                                                    formDispatch({ type: REQUEST_ERROR, payload:{ code } });
                                                }
                                            )
                                        ;
                                    }
                                }}
                            >
                                {useAuthSpinner
                                    ? <SpinnerWrapper>
                                        <AnimatedSpinner />
                                        {t('auth.signin.init_password')}
                                    </SpinnerWrapper>
                                    : t('auth.signin.init_password')
                                }
                            </Button>
                        </PartnerButton>
                    </>
                );
            }}
        />
    );
};
CognitoForgotPassword.propTypes = {
    colorBackground: propTypes.string.isRequired,
    Auth: propTypes.any,
};

export const ForgotPassword = ({ colorBackground }) => (
    <CognitoInteraction>
        {(Auth) => (
            <CognitoForgotPassword {...{
                colorBackground,
                Auth,
            }} />
        )}
    </CognitoInteraction>
);
ForgotPassword.propTypes = {
    colorBackground: propTypes.string.isRequired,
};

export default ForgotPassword;
