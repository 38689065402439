//@ts-check

import React, {
    useState,
    useEffect,
} from 'react';
import propTypes from 'prop-types';
import {
    useSelector,
} from 'react-redux';
import {
    useTranslation,
} from 'react-i18next';
import styled from 'styled-components';

import {
    useMatomo,
} from '@jonkoops/matomo-tracker-react';
import {
    Cognito,
} from '@testamento/react-components';
import {
    Button,
    Typography,
    Flashbag,
} from '@testamento/design-system';

import {
    AuthState,
} from '../../constants';
import {
    TranslationLayout,
} from '../../utils/react';
import ConfirmSignupForm, {
    useValidationState,
    useFieldsValue,
} from './forms/confirm-signup';
import {
    REGISTER_EFFECT,
    UNREGISTER_EFFECT,
    REGISTER_ERROR,
    UNREGISTER_ERROR,
    REQUEST_PENDING,
    REQUEST_ERROR,
    REQUEST_SUCCESS,
    SpacedFlashbag,
} from '../Forms';
import {
    handleErrorMessagesFromCognito,
} from '../../utils/errorCognitoHandling';

import {
    InputContainer,
} from '../Block/Form';
import {
    resetAuth,
} from '../../store/actions';
import store from '../../store';
import AnimatedSpinner, {
    SpinnerWrapper,
} from '../Block/Spinner/index';
import {
    PartnerButton,
} from '../Block/Styling';
import ConfirmationCode from './ConfirmationCode';
const {
    CognitoInteraction,
    useCognitoHubDispatch,
} = Cognito;

// This should change when token will come with targeted form support
const targetedState = AuthState.SignIn;
const NotAuthorizedException = 'NotAuthorizedException';


const SignInLink = ({ children }) => {
    const hubDispatch = useCognitoHubDispatch();
    return (
        <Typography.Link
            href='#/' onClick={(e) => {
                e.preventDefault();
                store.dispatch(resetAuth(targetedState, hubDispatch));
            }}
        >
            {children}
        </Typography.Link>
    );
};
SignInLink.propTypes = {
    children: propTypes.node,
};

const Paragraph = styled(Typography.Body)`
    text-align: left;
    a {
        font-weight: bold;
    }
`;

export const ConfirmSignUp = ({ colorBackground }) => {
    const { t } = useTranslation();
    const { trackPageView } = useMatomo();

    const user = useSelector(state => state.user);
    const signupCredentials = useSelector(state => state.signup);

    const [errorCode, setErrorCode] = useState('');
    const [confirmedUserStatus, updateConfirmedUserStatus] = useState(false);

    React.useEffect(() => {
        trackPageView({
            documentTitle: 'ConfirmSignup',
        });
    }, [trackPageView]);

    return (confirmedUserStatus
        ? <Flashbag
            background="primary"
            isLocked
            title={<TranslationLayout
                content={t('auth.signup.confirm.userAlreadyConfirmed')}
                mappings={{
                    p: Paragraph,
                    linkToSignin: SignInLink,
                }}
            />}
        />
        : <CognitoInteraction>
            {(Auth) => (
                <React.Fragment>
                    {errorCode !== ''
                        ? <Flashbag
                            background="primary"
                            isLocked
                            title={t(`auth.signup.confirm.${errorCode}`)}
                        />
                        : null
                    }
                    <ConfirmSignupForm
                        PreForm={function PreForm({ children, formDispatch }) {
                            const [cognitoErrorCode, setCognitoErrorCode] = useState(null);

                            useEffect(() => {
                                formDispatch({ type: REGISTER_ERROR, payload:{ effect: setCognitoErrorCode } });
                                return () => {
                                    formDispatch({ type: UNREGISTER_ERROR });
                                };
                            },[formDispatch, setCognitoErrorCode] );


                            return (
                                <>
                                    {cognitoErrorCode
                                        ? <SpacedFlashbag background='warning' title={t(handleErrorMessagesFromCognito(cognitoErrorCode))} isLocked />
                                        : null
                                    }
                                    <InputContainer>
                                        <Typography.HeadingXxs>{t('auth.signup.confirm.title')}</Typography.HeadingXxs>
                                    </InputContainer>
                                    <ConfirmationCode
                                        {...{
                                            setCognitoErrorCode,
                                            resendPromise: (userEmail) => Auth.resendSignUp(userEmail),
                                            userEmail: user.username,
                                            resentTranslationKey: 'auth.confirmation_code.resend_action',
                                        }}
                                    >
                                        {children}
                                    </ConfirmationCode>
                                </>
                            );
                        }}
                        PostForm={function PreForm({ formDispatch }) {
                            const state = useValidationState();
                            const values =  useFieldsValue();
                            const [useAuthSpinner, setUseAuthSpinner] = useState(false);

                            useEffect(() => {
                                formDispatch({ type: REGISTER_EFFECT, payload:{ effect: setUseAuthSpinner } });
                                return () => {
                                    formDispatch({ type: UNREGISTER_EFFECT });
                                };
                            },[formDispatch, setUseAuthSpinner] );

                            return (
                                <PartnerButton color="#FFFFFF" backgroundColor={ colorBackground }>
                                    <Button
                                        disabled={ useAuthSpinner ? true : false}
                                        onClick={(event) => {
                                            event.preventDefault();
                                            if(state()) {
                                                const { confirmationCode } = values();

                                                formDispatch({ type: REQUEST_PENDING });

                                                Auth.confirmSignUp(user.username, confirmationCode)
                                                    .then(() => {
                                                        Auth.signIn(user.username, signupCredentials.password).then(
                                                            () => {
                                                                formDispatch({ type: REQUEST_SUCCESS });
                                                            }
                                                        ).catch(
                                                            (errorSignIn) => {
                                                                const {
                                                                    code,
                                                                } = errorSignIn;

                                                                formDispatch({ type: REQUEST_ERROR, payload:{ code } });
                                                            }
                                                        );
                                                    })
                                                    .catch(confirmSignUpError => {
                                                        if (confirmSignUpError.code === NotAuthorizedException) {
                                                            updateConfirmedUserStatus(true);
                                                        } else {
                                                            setErrorCode(confirmSignUpError.code);
                                                        }
                                                    })
                                                ;
                                            }
                                        }}
                                    >
                                        {useAuthSpinner
                                            ? (
                                                <SpinnerWrapper>
                                                    <AnimatedSpinner />
                                                    {t('auth.signup.confirm.action')}
                                                </SpinnerWrapper>
                                            )
                                            : t('auth.signup.confirm.action')
                                        }
                                    </Button>
                                </PartnerButton>
                            );
                        }}
                        confirmationCodeLabelMessage={t('auth.signup.confirm.label')}
                        confirmationCodePlaceHolderMessage={t('auth.signup.confirm.placeholder')}
                        confirmationCodeErrorMessage={t('auth.signup.confirm.code_hint')}
                    />
                </React.Fragment>
            )}
        </CognitoInteraction>
    );
};

ConfirmSignUp.propTypes = {
    colorBackground: propTypes.string.isRequired,
};

export default ConfirmSignUp;
