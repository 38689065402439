//@ts-check

import React from 'react';
import {
    useTranslation,
} from 'react-i18next';
import propTypes from 'prop-types';
import {
    useSelector,
} from 'react-redux';
import {
    Toolbar,
    Grid,
} from '@testamento/design-system';
import {
    rgb,
} from 'd3-color';
import styled from 'styled-components';

import {
    getUrlWithPartner,
} from '../../utils/utils';
import {
    LandingHeader,
    LandingContent,
    LandingFooter,
} from '../LandingContent';
import NoticeBlock from '../Block/NoticeBlock';
import {
    AuthComponent,
} from '../Auth';
import LoaderReact from '../LoaderReact.jsx';

const Background = styled.section`
    background-image: url(${props => getUrlWithPartner(props.partner, props.backgroundImg)});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    width: 100%;
    height: auto;
`;

const BackgroundOverlay = styled.div`
    background-color: ${props => rgb(
        props.rgbColor.r,
        props.rgbColor.g,
        props.rgbColor.b,
        props.opacity
    )};
    height: auto;
`;

const FormFrame = styled.div`
    display: flex;
    justify-content: center;
`;

const FormContainer = styled.div`
    text-align: center;
    background-color: white;
    border-radius: .4rem;
    border: .1rem solid ${({ theme }) => theme.colors.borders};
    padding: 5.6rem 3.6rem;
    max-width: 41.4rem;
    width: 100%;
`;

const SignFormWrapper = ({ children }) => (
    <FormFrame>
        <FormContainer>
            {children}
        </FormContainer>
    </FormFrame>
);

SignFormWrapper.propTypes = {
    children: propTypes.any,
};

const scrollToLogin = () => {
    window.scrollTo(0, 0);
};

export const Landing = () => {
    const {
        global = {},
        appSettings = {},
    } = useSelector(state => state.config);
    const {
        logoFullResolution = '',
        colorBackground = '',
        colorHighlight = '',
    } = global;

    const {
        pageBackgroundImage = '',
    } = appSettings;

    const partner = useSelector(state => state.partner);
    const { t } = useTranslation();
    const loader = useSelector(state => state.loader);

    return (
        <>
            <Background
                partner={partner}
                backgroundImg={pageBackgroundImage}
            >
                <BackgroundOverlay
                    rgbColor={rgb(colorHighlight)}
                    opacity={0}
                >
                    <Toolbar>
                        <Toolbar.Frame>
                            <Toolbar.Logotype
                                src={logoFullResolution}
                                alt={t('logo_alternative_text')}
                                height='56px'
                            />
                        </Toolbar.Frame>
                    </Toolbar>
                    <Grid maxFreeze={'true'}>
                        <Grid.Row>
                            <Grid.Col size={1} breakPoint='lg'>
                                <LandingHeader />
                            </Grid.Col>
                            <Grid.Col size={1}>
                                {loader
                                    ? <LoaderReact />
                                    : <SignFormWrapper>
                                        <AuthComponent {...{ colorBackground }} />
                                    </SignFormWrapper>
                                }
                            </Grid.Col>
                        </Grid.Row>
                    </Grid>
                </BackgroundOverlay>
            </Background>
            <LandingContent {...{ global, colorBackground }} />
            <LandingFooter {...{ scrollToLogin }} />
            <NoticeBlock {...{ colorBackground }} />
        </>
    );
};

Landing.propTypes = {

};

export default Landing;
