import {
    logger,
} from '@testamento/design-system/dist/lib';
import store from '.';
import {
    DISPLAY_SIGN_ERROR_MESSAGE,
    SET_TOKEN_CONSUMPTION,
} from './action.types';
import parameters from '../parameters.json';
import {
    getPartnerFromDomain,
} from '../utils/utils';


const debug = logger('planner:action:postAuthentication');

const resolveValidationResults = ({
    notifyUserOfFailure,
    resolve,
}) => (options) => {
    switch (options.status) {
        case 401: {
            notifyUserOfFailure({
                message: 'auth.error.authorization_error',
            });
            store.dispatch({
                type: SET_TOKEN_CONSUMPTION,
                payload: false,
            });
            resolve(false);
            break;
        }
        case 200: {
            store.dispatch({
                type: SET_TOKEN_CONSUMPTION,
                payload: true,
            });
            resolve(true);
            break;
        }
        default: {
            store.dispatch({
                type: SET_TOKEN_CONSUMPTION,
                payload: false,
            });
            notifyUserOfFailure({
                message: 'auth.error.validation_unavailable',
            });
            resolve(false);
        }
    }
};

const validateRegistrationToken = ({
    Authorization,
    registrationtoken,
    partnername,
}) => fetch(
    `${parameters.public_api_url}/user/validate`,
    {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            Authorization,
        },
        body: JSON.stringify({
            registrationtoken,
            partnername,
        }),
        mode: 'cors',
    }
);
const validateGivenToken = ({
    Authorization,
    registrationtoken,
    partnername,
    notifyUserOfFailure,
}) => (resolve) => validateRegistrationToken({
    Authorization,
    registrationtoken,
    partnername,
}).then(
    resolveValidationResults({
        notifyUserOfFailure,
        resolve,
    })
).catch(
    (error) => {
        store.dispatch({
            type: SET_TOKEN_CONSUMPTION,
            payload: false,
        });
        notifyUserOfFailure({
            message: 'auth.error.validation_unavailable',
            error,
        });
        resolve(false);
    }
);

export const consumeToken = (Authorization, givenToken) => {
    const notifyUserOfFailure = ({ message, error }) => {
        store.dispatch({
            type: DISPLAY_SIGN_ERROR_MESSAGE,
            message,
            error,
        });
    };

    const registrationtoken = givenToken || '';
    const partnername = getPartnerFromDomain(window.location.host);
    debug(`consuming token ${registrationtoken} for partner ${partnername}`);

    return new Promise(
        validateGivenToken({
            Authorization,
            registrationtoken,
            partnername,
            notifyUserOfFailure,
        })
    );
};
