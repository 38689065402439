import React from 'react';
import propTypes from 'prop-types';
import {
    FormControl, 
} from '@testamento/design-system';
import {
    signUpEmailRule,
    mandatoryCheckRules, 
} from '../../../lib/validator';

import {
    FieldLabel,
    FieldWrapper,
    FormCell,
    ErrorRenderer, 
} from './styles';

const {
    Field,
    ControlledInput,
    DefaultInputField,
    TextInput,
} = FormControl;

/**
 *
 * @param {*} param0
 */
export default function EmailAndPasswordFields({
    passwordSetting,
    userEmailLabelMessage,
    userEmailPlaceholderMessage,
    initialUserEmailValue,
    userEmailErrorMessage,
    userPasswordLabelMessage,
    userPasswordPlaceholderMessage,
    userPasswordErrorMessage,
    userPasswordResetCallToAction,
}) {
    return (
        <>
            <Field Element={FieldWrapper}>
                <FieldLabel as="p">{userEmailLabelMessage}</FieldLabel>
                <FormCell>
                    <ControlledInput
                        ControlledElement={DefaultInputField}
                        name="userMail"
                        type="text"
                        placeholder={userEmailPlaceholderMessage}
                        value={initialUserEmailValue}
                        autoWidth={'true'}
                        validations={[
                            (value) => mandatoryCheckRules(value)
                                ? ''
                                : userEmailErrorMessage,
                            (value) => signUpEmailRule(value)
                                ? ''
                                : userEmailErrorMessage
                        ]}
                    />
                </FormCell>
                <ErrorRenderer name="userMail">
                    {userEmailErrorMessage}
                </ErrorRenderer>
            </Field>

            <Field Element={FieldWrapper}>
                <FieldLabel as="p">{userPasswordLabelMessage}</FieldLabel>
                <FormCell>
                    <ControlledInput
                        ControlledElement={TextInput.Password}
                        name="userPassword"
                        autoWidth={'true'}
                        placeholder={userPasswordPlaceholderMessage}
                        validations={passwordSetting.validations}
                    />
                </FormCell>
                <ErrorRenderer name="userPassword" description={passwordSetting.descriptionMode}>
                    {userPasswordErrorMessage}
                </ErrorRenderer>
                {userPasswordResetCallToAction
                    ? userPasswordResetCallToAction
                    : null
                }
            </Field>
        </>
    );
}
EmailAndPasswordFields.propTypes = {
    passwordSetting: propTypes.shape({
        descriptionMode: propTypes.bool,
        validations: propTypes.arrayOf(propTypes.func),
    }),
    userEmailLabelMessage: propTypes.string,
    userEmailPlaceholderMessage: propTypes.string,
    initialUserEmailValue: propTypes.string,
    userEmailErrorMessage: propTypes.string,
    userPasswordLabelMessage: propTypes.string,
    userPasswordPlaceholderMessage: propTypes.string,
    userPasswordErrorMessage: propTypes.string,
    userPasswordResetCallToAction: propTypes.node,
};
