import React from 'react';

/**
 *
 * @returns ReactSvgElement
 */
export default function Padlock () {
    return <svg
        width="49"
        height="48"
        viewBox="0 0 49 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <circle
            cx="24.3577"
            cy="24"
            r="23.5"
            stroke="#EB5831"
        />
        <g
            clipPath="url(#clip0_1193:7231)"
        >
            <path
                d="M31.1077 36H17.6077C16.3677 36 15.3577 34.991 15.3577 33.75V23.25C15.3577 22.009 16.3677 21 17.6077 21H31.1077C32.3477 21 33.3577 22.009 33.3577 23.25V33.75C33.3577 34.991 32.3477 36 31.1077 36ZM17.6077 22.5C17.1947 22.5 16.8577 22.836 16.8577 23.25V33.75C16.8577 34.164 17.1947 34.5 17.6077 34.5H31.1077C31.5207 34.5 31.8577 34.164 31.8577 33.75V23.25C31.8577 22.836 31.5207 22.5 31.1077 22.5H17.6077Z"
                fill="#EB5831"
            />
            <path
                d="M29.6077 22.5C29.1937 22.5 28.8577 22.164 28.8577 21.75V18C28.8577 15.519 26.8387 13.5 24.3577 13.5C21.8767 13.5 19.8577 15.519 19.8577 18V21.75C19.8577 22.164 19.5217 22.5 19.1077 22.5C18.6937 22.5 18.3577 22.164 18.3577 21.75V18C18.3577 14.691 21.0487 12 24.3577 12C27.6667 12 30.3577 14.691 30.3577 18V21.75C30.3577 22.164 30.0217 22.5 29.6077 22.5Z"
                fill="#EB5831"
            />
            <path
                d="M24.3577 29.0002C23.2547 29.0002 22.3577 28.1032 22.3577 27.0002C22.3577 25.8972 23.2547 25.0002 24.3577 25.0002C25.4607 25.0002 26.3577 25.8972 26.3577 27.0002C26.3577 28.1032 25.4607 29.0002 24.3577 29.0002ZM24.3577 26.5002C24.0827 26.5002 23.8577 26.7242 23.8577 27.0002C23.8577 27.2762 24.0827 27.5002 24.3577 27.5002C24.6327 27.5002 24.8577 27.2762 24.8577 27.0002C24.8577 26.7242 24.6327 26.5002 24.3577 26.5002Z"
                fill="#EB5831"
            />
            <path
                d="M24.3577 32C23.9437 32 23.6077 31.664 23.6077 31.25V28.5C23.6077 28.086 23.9437 27.75 24.3577 27.75C24.7717 27.75 25.1077 28.086 25.1077 28.5V31.25C25.1077 31.664 24.7717 32 24.3577 32Z"
                fill="#EB5831"
            />
        </g>
        <defs>
            <clipPath id="clip0_1193:7231">
                <rect width="24"
                    height="24"
                    fill="white"
                    transform="translate(12.3577 12)"
                />
            </clipPath>
        </defs>
    </svg>;
}
