import React from 'react';
import DefaultError from './DefaultError';

export const DefaultErrorRenderer = () => {
    // Translations are loaded after this page is supposed to be displayed,
    // therefore, they cannot be computed.
    // https://testamento.atlassian.net/browse/TESTA-5762

    return (
        <DefaultError
            title="Désolé, une erreur est survenue !"
            message="Nous vous prions de bien vouloir nous excuser et de réessayer plus tard."
        />
    );
};
