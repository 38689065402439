import {
    logger,
} from '@testamento/design-system/dist/lib';

import parameters from '../parameters.json';

const debug = logger('planner:trackEventService');
const trackingEventNames = [
    'signin',
    'signup'
];

import {
    getPartnerFromDomain,
} from './utils';

/**
 *
 * @param {*} token
 * @param {*} authenticationFlow
 * @returns Promise
 */
export default async function trackEventService(token, authenticationFlow) {
    if (trackingEventNames.includes(authenticationFlow)) {
        debug(`reporting serviceTrackEvent for ${authenticationFlow}`);

        return fetch(
            `${parameters.public_api_url}/services/event/track`,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token,
                },
                body: JSON.stringify(
                    {
                        partnername: `${getPartnerFromDomain(window.location.host)[0].toUpperCase()}${getPartnerFromDomain(window.location.host).slice(1)}`,
                        nextactionurl: `https://${window.location.host}`,
                        event: authenticationFlow,
                    }
                ),
                mode: 'cors',
            }
        );
    } else {
        debug(`event ${authenticationFlow} is not registered for trackEventService`);
    }
}
