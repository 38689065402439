//@ts-check

import React from 'react';
import propTypes from 'prop-types';
import {
    useTranslation,
} from 'react-i18next';
import {
    useMatomo,
} from '@jonkoops/matomo-tracker-react';
import {
    useSelector,
} from 'react-redux';
import {
    Typography,
    Button,
    FormControl,
} from '@testamento/design-system';
import {
    Cognito,
} from '@testamento/react-components';
import {
    useNavigate,
} from 'react-router-dom';
import {
    logger,
} from '@testamento/design-system/dist/lib';

import {
    switchUser,
} from '../store/actions';
import {
    DELETE_USER_FAILURE,
} from '../store/action.types';
import {
    getUserAuthorization,
} from '../utils/user';
import parameters from '../parameters.json';
import {
    PartnerButton,
} from '../components/Block/Styling';
import {
    HeadingSection,
    IconContainer,
    Section,
    PageLayout,
    Spacer,
    Container,
} from './styles';
import {
    SECURE_ACCOUNT_PARAMETERS_PATH,
    AMPLIFY_LOG_LEVEL,
} from '../constants';
import {
    RedirectionSetter,
} from '../components/ConnectedPage';
import DeleteAccountForm from '../components/Auth/forms/DeleteAccountForm';
import Trash from '../icons/Trash';
import store from '../store';

const {
    public_api_url,
} = parameters;

const deleteUser = async (Authorization, navigate) => {
    await fetch(
        `${public_api_url}/user/delete`,
        {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                Authorization,
            },
            mode: 'cors',
        }
    );

    for (let i = localStorage.length - 1; i >= 0; i--) {
        if (![
            'DEBUG',
            'telemetry',
            AMPLIFY_LOG_LEVEL
        ].includes(localStorage.key(i))) {
            localStorage.removeItem(localStorage.key(i));
        }
    }

    navigate('/logout');
    store.dispatch(switchUser('visitor'));

};

const debug = logger('planner:delete-account');
export const DeleteAccountWithSurvey = ({ children }) => {
    const { t } = useTranslation();
    return (
        <React.Fragment>
            <Section>
                <Typography.Body>{t('delete-account.description')}</Typography.Body>
            </Section>
            <FormControl>
                <Section background='#F4F7F9' >
                    <DeleteAccountForm />
                </Section>
                {children}
            </FormControl>
        </React.Fragment>
    );
};
DeleteAccountWithSurvey.propTypes = {
    children: propTypes.node,
};
const DeleteAccountWithoutSurvey = ({ children }) => {
    return (
        <FormControl>
            {children}
        </FormControl>
    );
};
DeleteAccountWithoutSurvey.propTypes = {
    children: propTypes.node,
};

/**
 *
 * @param {*} param0
 * @returns
 */
export default function DeleteAccount() {
    const { t } = useTranslation();
    const { trackEvent } = useMatomo();
    trackEvent({ category: 'DeleteAccount', action: 'delete-account-page-view' });
    const config = useSelector(state => state.config);
    const { global = {} } = config;
    const { useCognitoValuesWatcher } = Cognito;
    const navigate = useNavigate();
    const { user } = useCognitoValuesWatcher(['user'], 'userDispatch');
    const idToken = getUserAuthorization(user);

    return (
        <PageLayout>
            <HeadingSection>
                <IconContainer background='#F30606' >
                    <Trash color='white' />
                </IconContainer>
                <Typography.HeadingLg aria-label='page-title' >
                    {t('delete-account.title')}
                </Typography.HeadingLg>
            </HeadingSection>
            <Section emphasis='#F30606' >
                <Typography.BodyL weight='bold' >{t('delete-account.banner.title')}</Typography.BodyL>
                <Typography.Body>{t('delete-account.banner.content')}</Typography.Body>
            </Section>
            {/*
                Delete DeleteAccountWithoutSurvey component an use DeleteAccountWithSurvey
                when delete enpoint will
                - accept survey payload
                - support password validation
                All of this will be done in https://testamento.atlassian.net/browse/TESTA-5525
                */}
            <DeleteAccountWithoutSurvey>
                <Spacer />
                <Container>
                    <Typography.Body weight='bold'>{t('delete-account.form.action.download')}</Typography.Body>
                </Container>
                <Section rtl >
                    <PartnerButton
                        color={global.theme.colors.buttons.primary.fore}
                        backgroundColor={global.theme.colors.buttons.primary.background}>
                        <Button onClick={(event) => {
                            event.preventDefault();
                            deleteUser(idToken, navigate).catch((error) => {
                                debug({ error });
                                return store.dispatch({
                                    type: DELETE_USER_FAILURE,
                                    error: error.message,
                                });
                            });
                        }} >{t('delete-account.form.action.delete')}</Button>
                    </PartnerButton>
                    <RedirectionSetter>
                        {(setRedirectionPath) => (
                            <PartnerButton
                                modifier='secondary'
                                color={global.theme.colors.buttons.primary.fore}
                                backgroundColor={global.theme.colors.buttons.primary.background}>
                                <Button onClick={(event) => {
                                    event.preventDefault();
                                    setRedirectionPath(
                                        SECURE_ACCOUNT_PARAMETERS_PATH()
                                    );
                                }} >{t('delete-account.form.action.cancel')}</Button>
                            </PartnerButton>
                        )}
                    </RedirectionSetter>

                </Section>
            </DeleteAccountWithoutSurvey>
        </PageLayout>
    );
}

DeleteAccount.propTypes = {
    action: propTypes.string,
    title: propTypes.string,
    message: propTypes.string,
    colorBackground: propTypes.string,
};
