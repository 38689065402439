//@ts-check

import React, {
    useState,
    useEffect,
} from 'react';
import propTypes from 'prop-types';
import {
    useTranslation,
} from 'react-i18next';
import {
    useMatomo,
} from '@jonkoops/matomo-tracker-react';
import {
    FormHelperText,
    Button,
} from '@testamento/design-system';
import {
    Cognito,
} from '@testamento/react-components';

import {
    forgotPassword,
    prepareSignupCodeValidation,
    confirmSignUp,
} from '../../store/actions';
import store from '../../store';
import {
    REGISTER_EFFECT,
    UNREGISTER_EFFECT,
    REGISTER_ERROR,
    UNREGISTER_ERROR,
    REQUEST_PENDING,
    REQUEST_SUCCESS,
    REQUEST_ERROR,
    SpacedFlashbag,
} from '../Forms';
import {
    USER_NOT_CONFIRMED_EXCEPTION,
} from '../../constants';
import Signin, {
    useValidationState,
    useFieldsValue,
} from './forms/signin';
import AnimatedSpinner,{
    SpinnerWrapper,
} from '../Block/Spinner/index';
import {
    PartnerButton,
} from '../Block/Styling';
import {
    handleErrorMessagesFromCognito,
} from '../../utils/errorCognitoHandling';
const {
    CognitoInteraction,
    useCognitoHubDispatch,
} = Cognito;

const handleNotConfirmedUser = (Auth, formDispatch, userMail,  userPassword, hubDispatch)=>{
    Auth.resendSignUp(userMail).then(
        () => {
            store.dispatch(prepareSignupCodeValidation(userMail, userPassword));
            store.dispatch(confirmSignUp(hubDispatch));
        },
        (resendSignUpError) => {
            const {
                code,
            } = resendSignUpError;

            formDispatch({ type: REQUEST_ERROR, payload:{ code } });
        }
    ).catch((globalError) => {
        const {
            code,
        } = globalError;

        formDispatch({ type: REQUEST_ERROR, payload:{ code } });
    });
};

const CognitoSignin = ({ colorBackground, Auth }) => {
    const { trackPageView, trackEvent } = useMatomo();
    const hubDispatch = useCognitoHubDispatch();
    React.useEffect(() => {
        trackPageView({
            documentTitle: 'Signin',
        });
    }, [trackPageView]);

    const { t } = useTranslation();

    return (
        <Signin
            initialUserEmailValue={''}
            userEmailLabelMessage={t('auth.email')}
            userEmailPlaceholderMessage={t('auth.email')}
            userEmailErrorMessage={t('auth.signin.username_hint')}
            userPasswordLabelMessage={t('auth.password')}
            userPasswordPlaceholderMessage={t('auth.password')}
            userPasswordErrorMessage={t('auth.signin.password_hint')}
            userPasswordResetCallToAction={
                <FormHelperText.Link
                    onClick={() => store.dispatch(forgotPassword(
                        hubDispatch
                    ))}
                >
                    {t('auth.signin.forgot_password')}
                </FormHelperText.Link>
            }
            PreForm={function PreForm({ formDispatch }) {
                const [cognitoErrorCode, $cognitoErrorCode] = useState(null);

                useEffect(() => {
                    formDispatch({ type: REGISTER_ERROR, payload:{ effect: $cognitoErrorCode } });
                    return () => {
                        formDispatch({ type: UNREGISTER_ERROR });
                    };
                },[formDispatch, $cognitoErrorCode] );

                return  (cognitoErrorCode
                    ? <SpacedFlashbag background='warning' title={t(handleErrorMessagesFromCognito(cognitoErrorCode))}
                    />
                    : null
                );
            }}
            PostForm={function PostForm({ formDispatch }){
                const [useAuthSpinner, setUseAuthSpinner] = useState(false);
                useEffect(() => {
                    formDispatch({ type: REGISTER_EFFECT, payload:{ effect: setUseAuthSpinner } });
                    return () => {
                        formDispatch({ type: UNREGISTER_EFFECT });
                    };
                },[formDispatch, setUseAuthSpinner] );
                const state = useValidationState();
                const values =  useFieldsValue();
                return (
                    <PartnerButton color="#FFFFFF" backgroundColor={colorBackground} >
                        <Button
                            disabled={ useAuthSpinner ? true : false}
                            onClick={(event) => {
                                event.preventDefault();
                                if(state()) {
                                    const {
                                        userMail,
                                        userPassword,
                                    } = values();

                                    trackEvent({ category: 'Signin', action: 'click-signin-btn' });
                                    formDispatch({ type: REQUEST_PENDING });

                                    Auth.signIn(userMail, userPassword)
                                        .then(() => {
                                            formDispatch({ type: REQUEST_SUCCESS });
                                        })
                                        .catch((errorSignIn) => {
                                            if(errorSignIn.code === USER_NOT_CONFIRMED_EXCEPTION) {
                                                handleNotConfirmedUser(
                                                    Auth,
                                                    formDispatch,
                                                    userMail,
                                                    userPassword,
                                                    hubDispatch
                                                );
                                            } else {
                                                formDispatch({ type:REQUEST_ERROR, payload:{ code: errorSignIn.code } });
                                            }
                                        })
                                    ;
                                }
                            }}
                        >
                            {useAuthSpinner
                                ? (
                                    <SpinnerWrapper>
                                        <AnimatedSpinner alt={t('auth.signin.connection.pending')} />
                                        {t('auth.signin.connect')}
                                    </SpinnerWrapper>
                                )
                                : t('auth.signin.connect')
                            }
                        </Button>
                    </PartnerButton>
                );
            }}
        />
    );
};
CognitoSignin.propTypes = {
    colorBackground: propTypes.string,
    Auth: propTypes.any,
};

export const SignIn = ({ colorBackground }) => (
    <CognitoInteraction>
        {(Auth) => (
            <CognitoSignin {...{
                colorBackground,
                Auth,
            }} />
        )}
    </CognitoInteraction>
);
SignIn.propTypes = {
    colorBackground: propTypes.string,
};

export default SignIn;
