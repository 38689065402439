/**
 * @module 'src/store/Journal'
 */
import {
    AuthState,
} from '../constants';
import {
    combineReducers,
} from 'redux';
import {
    SWITCH_USER,
    SET_PARTNER,
    SET_CONFIG,
    TOKEN_VALIDATED,
    TOKEN_INVALIDATED,
    SET_TOKEN_CONSUMPTION,
    PREPARE_SIGNUP_CODE_VALIDATION,
    DISPLAY_SIGN_ERROR_MESSAGE,
    RESET_SIGN_ERROR_MESSAGE,
    DISPLAY_LOADER,
    HIDE_LOADER,
    DELETE_USER_SUCCESS,
    DELETE_USER_FAILURE,
} from './action.types';

/**
 *
 * @param {*} state
 * @param {*} action
 */
function partner(state = 'default', action = { type: undefined }) {
    if (action.type === SET_PARTNER) {
        return action.partner;
    } else {
        return state;
    }
}

/**
 *
 * @param {*} state
 * @param {*} action
 */
function config(state = {}, action = { type: undefined }) {
    if (action.type === SET_CONFIG) {
        const { global = {}, appSettings = {} } = action.config;
        const { planner = {} } = appSettings;
        const { authProviders = [] } = planner;
        if (!authProviders || authProviders.length === 0) {
            return ({
                global : {
                    ...global,
                    authProviders : ['Cognito'],
                },
                appSettings : planner,
            });
        } else {
            return ({
                global: {
                    ...global,
                    authProviders,
                },
                appSettings : planner,
            });
        }
    } else {
        return state;
    }
}

/**
 *
 * @param {*} state
 * @param {*} action
 */
function user(state = null, action = { type: undefined }) {
    if (action.type === SWITCH_USER) {
        return action.user;
    }
    else {
        return state;
    }
}

/**
 *
 * @param {*} state
 * @param {*} action
 */
const authState = (state = AuthState.SignIn, action = { type: undefined }) => {
    if (
        [
            AuthState.SignUp,
            AuthState.SignIn,
            AuthState.ForgotPassword,
            AuthState.ResetPassword,
            AuthState.ConfirmSignUp
        ].includes(action.type)
    ) {
        return action.authState;
    }

    return state;
};

const consumeTokenState = (
    state = null,
    action = { type: undefined, payload: false }
) => {
    if (action.type === SET_TOKEN_CONSUMPTION) {
        return action.payload;
    }
    return state;
};

/**
 * Reduce
 *
 * @param {null|boolean} state
 * @param {Object} action
 * @param {string} action.type
 * @param {Object} [action.error]
 * error
 */
function validatedToken(state = null, action = { type: undefined }) {
    switch (action.type) {
        case TOKEN_VALIDATED: {
            return true;
        }
        case TOKEN_INVALIDATED: {
            return false;
        }
        default: {
            return state;
        }
    }
}

/**
 * Reduce sign form error messages state
 *
 * @param {*} state
 * @param {Object} action
 * @param {String} action.type
 * @param {String} [action.message]
 * @param {Object} [action.error]
 */
function signActionError(state = null, action = { type: undefined }) {
    switch (action.type) {
        case DISPLAY_SIGN_ERROR_MESSAGE: {
            return action.message;
        }
        case RESET_SIGN_ERROR_MESSAGE: {
            return null;
        }
        default: {
            return state;
        }
    }
}

/**
 *
 * @param {*} state
 * @param {*} action
 */
function signup (state = null, action = { type: undefined, payload: {} }) {
    if (action.type === PREPARE_SIGNUP_CODE_VALIDATION) {
        return action.payload;
    }
    return state;
}

/**
 *
 * @param {*} state
 * @param {*} action
 */
function loader (state = true, action = { type: undefined, payload: {} }) {
    if (action.type === DISPLAY_LOADER) {
        return true;
    }
    if (action.type === HIDE_LOADER) {
        return false;
    }
    return state;
}

/**
 *
 * @param {*} state
 * @param {*} action
 * @returns
 */
function deleteUser(state = null, action = { type: undefined, error: {} }) {
    switch (action.type) {
        case DELETE_USER_SUCCESS: {
            return true;
        }
        case DELETE_USER_FAILURE: {
            return action.error;
        }
        default: {
            return state;
        }
    }
}


const Journal = combineReducers({
    config,
    partner,
    user,
    authState,
    validatedToken,
    consumeTokenState,
    signup,
    signActionError,
    deleteUser,
    loader,
});

export default Journal;
