import React from 'react';
import propTypes from 'prop-types';
import styled, {
    css,
} from 'styled-components';
import {
    Grid,
    FormControl,
    Typography,
} from '@testamento/design-system';

const {
    useFieldErrorNotifier,
} = FormControl;

const { Col } = Grid;

const labelBackground = css`
${({ background }) => (
        background
            ? background
            : 'blue'
    )};
`;

const paddingModifiers = {
    py: css`
    ${({ py }) => (
        (py && py === 'small')
            ? '5px 0px;'
            : '13px 0px;'
    )}`,
};

const alignModifiers = {
    default: css`
    align-items: flex-start;
    `,
    center: css`
    align-items: center;
        p {
            padding: 0;
            font-size: 14px;
        }`,
};

const applyAlignModifiers = css`
     ${({ align }) => (
        (align && align === 'center')
            ? alignModifiers.center
            : alignModifiers.default
    )};
`;

export const FormCell = styled(Col)`
    margin: 0;
    padding: 0 0 .9rem 0;
    display: block;
`;

export const LabelWrapper = styled.span`
    display: flex;
    align-items: center;
    gap: 9px;
`;

export const LabelIndicator = styled.span`
    background: ${labelBackground};
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 24px;
    min-height: 24px;
    border-radius: 100%;
    font-size: 12px;
    font-weight: bold;
`;

export const CheckBoxWrapper = styled(Col)`
    margin : 0;
    padding: 0;
    display: flex;
    flex-direction: row;
    cursor: pointer;
    text-align: left;
    padding: ${paddingModifiers.py};
   
    ${applyAlignModifiers};
`;

export const FieldWrapper = styled.label`
    display: block;
    text-align: start;
    margin: 0 0 2rem;
    width : 100%;
`;

export const Fieldset = styled.fieldset`
    all: unset;
    width: 100%;
    :first-of-type, :last-of-type {
        margin: 0;
    }
    margin: 23px 0;
`;


export const FieldLabel = ({
    children,
    ...restProps
}) => (
    <Typography.Label additionalStyle={`
        margin: 0;
        padding: 0 0 .9rem 0;
    `} {...restProps}>
        {children}
    </Typography.Label>
);
FieldLabel.propTypes = {
    children: propTypes.node,
};

const DescriptionContainer = ({
    children,
    description,
    ...restProps
}) => (description
    ? <Typography.Label additionalStyle={`
        font-weight: normal;
        color: rgb(155, 155, 155);
    `} {...restProps}>
        {children}
    </Typography.Label>
    : null
);
DescriptionContainer.propTypes = {
    children: propTypes.node,
    description: propTypes.bool,
};

const ErrorContainer = ({
    children,
    ...restProps
}) => (
    <Typography.Label additionalStyle={`
        font-weight: normal;
        color: #EB5831;
    `} {...restProps}>
        {children}
    </Typography.Label>
);
ErrorContainer.propTypes = {
    children: propTypes.node,
};

export const ErrorRenderer = ({ children, name, description }) => {
    const errors = useFieldErrorNotifier(name);

    return (errors && errors.length
        ? <ErrorContainer as="p">{children}</ErrorContainer>
        : <DescriptionContainer
            {...{
                description,
            }}
        >
            {children}
        </DescriptionContainer>
    );
};
ErrorRenderer.propTypes = {
    children: propTypes.node,
    name: propTypes.string,
    description: propTypes.bool,
};
