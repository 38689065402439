//@ts-check

import React from 'react';
import {
    Footer,
    Typography,
    Grid,
} from '@testamento/design-system';
import styled from 'styled-components';
import propTypes from 'prop-types';
import {
    arrayToLines,
} from '../../utils/react';

const FooterFrame = styled.div`
    margin: 0 0 5.6rem;
`;
const ColumnTitle = styled(Typography.HeadingMd)`
    font-size: 1.6rem;
    font-style: normal;
    font-weight: bold;
    line-height: 2em;
    margin-bottom: 2rem;
    text-transform: uppercase;
    color: #000000;
    white-space: nowrap;
`;
const FooterBorderedRow = styled.div`
    border-top: .1rem solid ${({ theme }) => theme.colors.borders};
    padding: 1.3rem 0;
`;
/**
 *
 * @param {import('../../..').FooterLink} props
 * @returns
 */
function FooterLink({
    href,
    content,
}) {
    return <Typography.Link
        href={href}
        target="_blank"
        rel="noopener noreferrer"
        title={content}
    >
        {content}
    </Typography.Link>;
}
FooterLink.propTypes = {
    href: propTypes.string,
    content: propTypes.string,
};


/**
 *
 * @param {import('../../..').FooterLinksColumnProps} props
 */
function FooterLinksColumn({
    columnTitle,
    columnLinkList,
}) {
    return (
        <Footer.Col size="1" >
            {Array.isArray(columnTitle) && columnTitle.length > 0
                ? <ColumnTitle>{columnTitle.map(arrayToLines)}</ColumnTitle>
                : null
            }
            {columnLinkList.map(
                (link, i) => (
                    <FooterLink
                        key={i}
                        {...link}
                    />
                )
            )}
        </Footer.Col>
    );
}
FooterLinksColumn.propTypes = {
    columnTitle: propTypes.arrayOf(propTypes.string),
    columnLinkList: propTypes.arrayOf(
        propTypes.shape({
            content: propTypes.string,
            href: propTypes.string,
        })
    ).isRequired,
};


/**
 *
 * @param {import('../../..').FooterBlockProps} props
 * @returns
 */
function FooterLinkContent({ content }) {
    return (
        <FooterLinksColumn
            columnTitle={content.links_title}
            columnLinkList={content.links}
        />
    );
}
FooterLinkContent.propTypes = {
    content: propTypes.shape({
        links_title: propTypes.arrayOf(propTypes.string),
        links: propTypes.arrayOf(
            propTypes.shape({
                content: propTypes.string,
                href: propTypes.string,
            })
        ),
    }),
};

/**
 *
 * @param {import('../../..').FooterBlockProps} props
 * @returns
 */
function FooterHelpContent({
    content,
}) {
    return (
        <FooterLinksColumn
            columnTitle={content.help_title}
            columnLinkList={content.help}
        />
    );
}
FooterHelpContent.propTypes = {
    content: propTypes.shape({
        help_title: propTypes.arrayOf(propTypes.string),
        help: propTypes.arrayOf(
            propTypes.shape({
                content: propTypes.string,
                href: propTypes.string,
            })
        ),
    }),
};

/**
 *
 * @returns
 */
function EmptyColumn() {
    return (
        <Footer.Col
            size="1"
        />
    );
}

/**
 *
 * @param {import('../../..').FooterBlockProps} props
 * @returns
 */
function ContactContent({ content }) {

    return (
        <Footer.Col size="1" >
            {content.address_title
                ? <ColumnTitle>
                    {content.address_title.map(arrayToLines)}
                </ColumnTitle>
                : null
            }
            {(content.address && content.address.length > 0)
                ? content.address.map((addressLine, i) => (
                    <Typography.Body
                        key={i}
                    >
                        {addressLine}
                    </Typography.Body>
                ))
                : null
            }
            {(content.email)
                ? <Typography.Link
                    href={`mailto:${content.email.address}`}
                >
                    {content.email.name}
                </Typography.Link>
                : null
            }
        </Footer.Col>
    );
}
ContactContent.propTypes = {
    content: propTypes.shape({
        address_title: propTypes.arrayOf(propTypes.string),
        address: propTypes.arrayOf(propTypes.string),
        email: propTypes.shape({
            address: propTypes.string,
            name: propTypes.string,
        }),
    }),
};


/**
 *
 * @param {import('../../..').FooterBlockProps} props
 * @returns
 */
function FooterLegalMention({
    content,
}) {
    if (content.legal_mentions === undefined) {
        return null;
    }

    const handleLegalMention = () => {
        if (content.legal_mentions_href) {
            window.open(content.legal_mentions_href, '_blank');
        }
    };

    return (
        <Grid maxFreeze={'true'}>
            <Grid.Col size={1}>
                <FooterBorderedRow>
                    <Typography.Body
                        onClick={() => handleLegalMention()}
                        style={{
                            cursor: (content.legal_mentions_href
                                ? 'pointer'
                                : ''
                            ),
                        }}
                    >
                        {content.legal_mentions}
                    </Typography.Body>
                </FooterBorderedRow>
            </Grid.Col>
        </Grid>
    );
}
FooterLegalMention.propTypes = {
    content: propTypes.shape({
        legal_mentions_href: propTypes.string,
        legal_mentions: propTypes.string,
    }),
};

/**
 *
 * @param {import('../../..').FooterContent} content: ;
 */
function* generateFooterContent(content) {
    if (content.links && content.links.length > 0) {
        yield FooterLinkContent;
    }
    if (content.help && content.help.length > 0) {
        yield FooterHelpContent;
    } else {
        yield EmptyColumn;
    }
    if (content.address || content.email) {
        yield ContactContent;
    }
}

/**
 *
 * @param {import('../../..').FooterBlockProps} props
 * @returns
 */
export default function FooterBlock({ content }) {
    return (
        <FooterFrame>
            <Footer>
                {Array.from(generateFooterContent(content)).map(
                    (FooterItem, index) => (
                        <FooterItem
                            {...{
                                content,
                            }}
                            key={index}
                        />
                    )
                )}
            </Footer>
            <FooterLegalMention
                {...{
                    content,
                }}
            />
        </FooterFrame>
    );
}

FooterBlock.propTypes = {
    content: propTypes.shape({
        links_title: propTypes.arrayOf(propTypes.string),
        links: propTypes.arrayOf(propTypes.shape({
            content: propTypes.string,
            href: propTypes.string,
        })),
        help_title: propTypes.arrayOf(propTypes.string),
        help: propTypes.arrayOf(propTypes.shape({
            content: propTypes.string,
            href: propTypes.string,
        })),
        legal_mentions: propTypes.string,
        legal_mentions_href: propTypes.string,
        address_title: propTypes.arrayOf(propTypes.string),
        address: propTypes.arrayOf(propTypes.string),
        email: propTypes.shape({
            address: propTypes.string,
            name: propTypes.string,
        }),
    }),
};
