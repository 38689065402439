import {
    DEFAULT_PARTNER, 
} from '../constants';

export const isEmpty = (value) => {
    return value === void 0 || value === null || value === '';
};

export const LANG_NAMESPACES = [
    'translation'
];

export const LANG_SEPARATOR = '/';

const LANG_TEMPLATE_PARAMETER = '{template}';
const LANG_LNG_PARAMETER = '{lang}';
const LANG_NAMESPACE_PARAMETER = '{namespace}';

export const i18nLoadPath = (defaultTemplate, translationsUrl, lang, namespace) => {
    let template = defaultTemplate;
    let language = lang[0];

    if (lang[0].lastIndexOf(LANG_SEPARATOR) > 0) {
        const parameters = lang[0].split(LANG_SEPARATOR);
        language = parameters[0];
        template = isEmpty(parameters[1]) ? template : parameters[1];
    }

    return translationsUrl.replace(LANG_LNG_PARAMETER, language)
        .replace(LANG_TEMPLATE_PARAMETER, template)
        .replace(LANG_NAMESPACE_PARAMETER, namespace);
};

export const joinI18n = (base, contexts) => {
    let result = base;
    for (let i = 0; i < contexts.length; i++) {
        if (!isEmpty(contexts[i])) {
            result += '.' + contexts[i];
        }
    }

    return result.toLocaleLowerCase();
};

export const geti18n = (t, groupId, key, ...others) => {
    const joinedKey = joinI18n(key, [groupId]);
    const generateTrans = t(joinedKey, ...others);
    return generateTrans === joinedKey ? t(joinI18n(key, ['default']), ...others) : generateTrans;
};

export const getPartnerFromDomain = (domain) => {
    const extractedPartnerPath = domain.match(
        /^(?:([^.]+)\.)?(?:(local|dev|stage)-)?planner\.([^.]{2,63}\.[^.]{2,5})(?::\d+)?$/
    );

    if (extractedPartnerPath) {
        // below show all available information extracted from the given domain :
        // const [, partnerName, environment, productDomain] =extractedPartnerPath;
        const [, partnerName] = extractedPartnerPath;
        return partnerName || DEFAULT_PARTNER;
    }

    return DEFAULT_PARTNER;
};

/**
 *
 * @param {string} partner
 * @param {string} baseUrl
 */
export const getUrlWithPartner = (partner, baseUrl) => baseUrl.replace(
    '{partner}',
    partner || 'default'
);

/**
 *
 * @param {string} partner
 * @param {Array} supportedLangs
 */
export const buildWhitelistedLangs = (partner, supportedLangs) => {

    const whiteListedLangs = [];
    const langList = isEmpty(supportedLangs) ? ['fr'] : supportedLangs;

    langList.forEach(lang => {
        whiteListedLangs.push(`${lang}/default`);
        whiteListedLangs.push(`${lang}/${partner}`);
        whiteListedLangs.push(lang);
    });


    return whiteListedLangs;
};
