//@ts-check

import {
    publicIpv4,
} from 'public-ip';
import {
    logger,
} from '@testamento/design-system/dist/lib';

import parameters from '../parameters.json';
import {
    getPartnerFromDomain,
} from './utils';

const debug = logger('planner:trackActivity');
const cognitoRegisteredTracking = [
    'signin',
    'signup',
    'reset password',
    'change password',
    'delete account',
    'logout'
];
/**
 *
 * @param {*} token
 * @param {*} authenticationFlow
 * @returns Promise
 */
export default async function trackActivity(token, authenticationFlow) {
    const clientPublicIp = await publicIpv4();
    if(cognitoRegisteredTracking.includes(authenticationFlow)) {
        debug(`reporting trackActivity for ${authenticationFlow} with Authorization token ${token}`);
        return fetch(
            `${parameters.public_api_url}/trackactivity/event`,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token,
                },
                body: JSON.stringify({
                    event: authenticationFlow,
                    data: {
                        partner: getPartnerFromDomain(window.location.host),
                        ip: clientPublicIp,
                    },
                }),
                mode: 'cors',
            }
        );
    } else {
        return Promise.resolve({
            ok: false,
            json: ()=>Promise.resolve({
                error: `${authenticationFlow} event is not registered for trackActivity`,
            }),
        });
    }
}
