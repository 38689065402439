import React, {
    Fragment,
} from 'react';
import propTypes from 'prop-types';

import {
    DefaultErrorRenderer,
} from '../pages/DefaultErrorRenderer';
import {
    logger,
} from '@testamento/design-system/dist/lib';

export const NullComponent = () => null;

const debug = logger('planner:react-utils');
export const arrayToLines = (Item, i, array) => (i === array.length - 1)
    ? <Fragment key={i}>{Item}</Fragment>
    : <Fragment key={i}>{Item}<br /></Fragment>;


export class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.ErrorRenderer = props.ErrorRenderer;
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        return { hasError: true, error };
    }

    componentDidCatch(error, errorInfo) {
        if (process.env.NODE_ENV === 'dev') {
            debug({
                error,
                errorInfo,
            });
        }

        try {
            // eslint-disable-next-line no-undef
            _paq.push(['trackEvent', 'JS Errors', error.message, error.stack.split('\n').slice(1)[0]]);
        } catch (e) {
            debug(e);
        }
    }

    render() {
        if (this.state.hasError) {
            const ErrorRenderer = (this.ErrorRenderer
                ? this.ErrorRenderer
                : DefaultErrorRenderer
            );

            return <ErrorRenderer error={this.state.error} />;
        }

        return this.props.children;
    }
}
ErrorBoundary.propTypes = {
    children: propTypes.any,
    ErrorRenderer: propTypes.any,
};

export { default as TranslationLayout } from '../lib/renderer';
