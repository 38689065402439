
export const SWITCH_USER = 'SWITCH_USER';
export const SET_PARTNER = 'SET_PARTNER';
export const SET_CONFIG = 'SET_CONFIG';
export const TOKEN_VALIDATED = 'TOKEN_VALIDATED';
export const TOKEN_INVALIDATED = 'TOKEN_INVALIDATED';
export const SET_TOKEN_CONSUMPTION = 'SET_TOKEN_CONSUMPTION';
export const PREPARE_SIGNUP_CODE_VALIDATION = 'PREPARE_SIGNUP_CODE_VALIDATION';
export const DISPLAY_SIGN_ERROR_MESSAGE = 'DISPLAY_SIGN_ERROR_MESSAGE';
export const RESET_SIGN_ERROR_MESSAGE = 'RESET_SIGN_ERROR_MESSAGE';
export const DISPLAY_LOADER = 'DISPLAY_LOADER';
export const HIDE_LOADER = 'HIDE_LOADER';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const DELETE_USER_FAILURE = 'DELETE_USER_FAILURE';
