import React from 'react';
import propTypes from 'prop-types';
import CognitoOAuthError from '../pages/CognitoOauthError';
import {
    Cognito,
} from '@testamento/react-components';
const {
    useCognitoValuesWatcher,
} = Cognito;


/**
 *
 * @returns
 */
export default function CognitoOAuth({ children, colorBackground }) {
    const {
        oAuthError,
    } = useCognitoValuesWatcher(['oAuthError'], 'authDispatch');

    return (oAuthError
        ? <CognitoOAuthError {...{ oAuthError, colorBackground }}/>
        : children
    );
}
CognitoOAuth.propTypes= {
    children: propTypes.node,
    colorBackground: propTypes.string,
};
