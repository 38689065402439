import {
    useEffect,
} from 'react';

export const useNumField = () => {
    useEffect(() => {
        const handleWheel = (event) => event.preventDefault();
        const numberInputs = document.querySelectorAll('input[type="number"]');

        numberInputs.forEach((input) =>
            input.addEventListener('wheel', handleWheel, { passive: false }));

        return () => {
            numberInputs.forEach((input) =>
                input.removeEventListener('wheel', handleWheel));
        };
    }, []);
};
