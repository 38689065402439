
import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import {
    useNumField,
} from '../../../hooks/useNumField';
import {
    FormControl,
} from '@testamento/design-system';
import {
    mandatoryCheckRules,
    confirmationCodeRule,
} from '../../../lib/validator';

import {
    FieldLabel,
    FieldWrapper,
    FormCell,
    ErrorRenderer,
} from './styles';
import {
    NullComponent,
} from '../../../utils/react';
import Remote, {
    useRemoteState,
} from '../../Forms';

const {
    Field,
    ControlledInput,
    DefaultInputField,
    useValidationErrors,
    useValidationState,
    useFieldsError,
    useFieldsValue,
} = FormControl;

const BasicNumField = styled(DefaultInputField)`
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    &[type=number] {
        -moz-appearance: textfield; /* Firefox */
    }
`;

const ConfirmSignup = ({
    confirmationCodeLabelMessage,
    confirmationCodePlaceholderMessage,
    confirmationCodeErrorMessage,
    PreForm,
    PostForm,
}) => {
    const dispatch = useRemoteState();

    useNumField(); // Disable native scroll feature on number fields
    return (
        <FormControl>
            <PreForm formDispatch={dispatch}>
                <Field Element={FieldWrapper}>
                    <FieldLabel as="p">{confirmationCodeLabelMessage}</FieldLabel>
                    <FormCell>
                        <ControlledInput
                            ControlledElement={BasicNumField}
                            name="confirmationCode"
                            type="number"
                            placeholder={confirmationCodePlaceholderMessage}
                            value=""
                            autoWidth={'true'}
                            validations={[
                                (value) => (mandatoryCheckRules(value)
                                    ? ''
                                    : confirmationCodeErrorMessage
                                ),
                                (value) => (confirmationCodeRule(value)
                                    ? ''
                                    : confirmationCodeErrorMessage
                                )
                            ]}
                        />
                    </FormCell>
                    <ErrorRenderer name="confirmationCode">
                        {confirmationCodeErrorMessage}
                    </ErrorRenderer>
                </Field>
            </PreForm>
            <PostForm  formDispatch={dispatch} />
        </FormControl>
    );
};
ConfirmSignup.defaultProps = {
    PreForm: NullComponent,
    PostForm: NullComponent,
};
ConfirmSignup.propTypes = {
    PreForm: propTypes.func,
    PostForm: propTypes.func,

    confirmationCodeLabelMessage: propTypes.string,
    confirmationCodePlaceholderMessage: propTypes.string,
    confirmationCodeErrorMessage: propTypes.string,
};

/**
 *
 * @param {*} param0
 */
export default function ConfirmSignupWrapper ({ children, ...props }) {
    return (
        <Remote>
            <ConfirmSignup {...props}>
                {children}
            </ConfirmSignup>
        </Remote>
    );
}
ConfirmSignupWrapper.propTypes = {
    children: propTypes.node,
};

export {
    useValidationErrors,
    useValidationState,
    useFieldsError,
    useFieldsValue
};
